import alt from '../alt';
import filterAction from '../actions/filterAction';

class filterStore {
    constructor() {
        this.bindActions(filterAction);
        this.iniLoadGeneral = false;
        this.iniLoadItem = false;
        this.iniLoadCategory = false;
        this.iniLoadEmployee = false;
        this.iniLoadSupplier = false;
        this.iniLoadOutlet = false;
        this.iniLoadCustomerGrp = false;
        this.iniLoadCustomer = false;
        this.iniLoadUnit = false;
        this.currencySymbol = "";
        this.taxName = "";
        this.tax = 0;
        this.taxMode = 0; //0 is apply to all, 1 is individual
        this.employees = [];
        this.suppliers = [];
        this.categoryList = [];
        this.groupList = [];
        this.itemList = [];
        this.unitList = [];
        this.shiftLabel = [];
        this.company = {
            _id: "",
            companyName: "",
            selfOrder: false
        }
        this.outlets = [];
        this.payment = {
            serviceChargeMode: 0,
            serviceCharge: 0,
            serviceTax: 0,
        };
        this.customerGroup = [];
        this.customers = [];
        this.iniLoadPricebook = false;
        this.pricebookList = [];
        this.iniLoadPaymentMethod = false;
        this.paymentMethods = [];
        this.deviceList = [];
    }
    onGroupList(data){
        this.groupList = data;
    }
    onDeviceList(data){
        this.deviceList = data;
    }
    onPaymentMethods(data){
        this.iniLoadPaymentMethod=  true;
        this.paymentMethods = data;
    }
    onPricebookList(data) {
        this.iniLoadPricebook = data;
        this.pricebookList = data;
    }
    onCustomerGroup(data) {
        this.iniLoadCustomerGrp = true;
        this.customerGroup = data;
    }
    onCustomerGrpUpdate(data) {
        if (!this.iniLoadCustomerGrp) return;
        this.customerGroup = this.customerGroup.concat(data);
    }
    onCustomerGrpRemove(data) {
        if (!this.iniLoadCustomerGrp) return;

        for (let i = 0; i < this.customerGroup.length; i++) {
            if (this.customerGroup[i]._id == data) {
                this.customerGroup.splice(i, 1);
                break;
            }
        }
    }
    onCustomers(data) {
        this.iniLoadCustomer = true;
        this.customers = data;
    }
    onCustomerUpdate(data) {
        if (!this.iniLoadCustomer) return;
        this.customers = this.customers.concat(data);
    }
    onCustomerRemove(data) {
        if (!this.iniLoadCustomer) return;

        for (let i = 0; i < this.customers.length; i++) {
            if (this.customers[i]._id == data) {
                this.customers.splice(i, 1);
                break;
            }
        }
    }
    onPayment(data) {
        this.payment = data;
    }
    onOutlets(data) {
        this.iniLoadOutlet = true;
        this.outlets = data;
    }
    onEmployees(data) {
        this.iniLoadEmployee = true;
        this.employees = data;
    }
    onUnitList(data) {
        this.iniLoadUnit = true;
        this.unitList = data;
    }
    onUnitUpdate(data) {
        if (!this.iniLoadUnit) return;
        this.unitList = this.unitList.concat(data);
    }
    onUnitRemove(data) {
        if (!this.iniLoadUnit) return;

        for (let i = 0; i < this.unitList.length; i++) {
            if (this.unitList[i]._id == data) {
                this.unitList.splice(i, 1);
                break;
            }
        }
    }
    onCategoryList(data) {
        this.iniLoadCategory = true;
        this.categoryList = data;
    }
    onCategoryUpdate(data) {
        if (!this.iniLoadCategory) return;
        let isRepeat = false;
        for (let i = 0; i < this.categoryList.length; i++) {
            if (this.categoryList[i].category == data.category) {
                this.categoryList[i] = data;
                isRepeat = true;
                break;
            }
        }
        if (!isRepeat) {
            this.categoryList = this.categoryList.concat(data);
        }
    }
    onCategoryRemove(data) {
        if (!this.iniLoadCategory) return;

        for (let i = 0; i < this.categoryList.length; i++) {
            if (this.categoryList[i].category == data) {
                this.categoryList.splice(i, 1);
                break;
            }
        }
    }
    onItemList(data) {
        this.iniLoadItem = true;
        this.itemList = data;
    }
    onCurrencySymbol(data) {
        this.currencySymbol = data;
    }
    onTax(data) {
        this.tax = data;
    }
    onTaxMode(data) {
        this.taxMode = data;
    }
    onTaxName(data) {
        this.taxName = data;
    }
    onCompany(data) {
        this.company = data;
        this.iniLoadGeneral = true;
    }
    onSuppliers(data) {
        this.suppliers = data;
    }
    onSuppliersUpdate(data) {
        let isRepeat = false;

        if (!this.iniLoadSupplier) return;

        for (let i = 0; i < this.suppliers.length; i++) {
            if (this.suppliers[i].supplierId == data.supplierId) {
                isRepeat = true;
                this.suppliers[i].supplierName = data.supplierName;
                break;
            }
        }

        if (!isRepeat) {
            this.supplierName = this.supplierName.concat({
                supplierId: data.supplierId,
                supplierName: data.supplierName,
            });
        }
    }
    onSuppliersRemove(data) {
        if (!this.iniLoadSupplier) return;

        for (let i = 0; i < this.suppliers.length; i++) {
            if (this.suppliers[i].supplierId == data) {
                this.suppliers.splice(i, 1);
                break;
            }
        }
    }
    onEmployeesUpdate(data) {
        let isRepeat = false;

        if (!this.iniLoadEmployee) return;

        for (let i = 0; i < this.employees.length; i++) {
            if (this.employees[i]._id == data._id) {
                isRepeat = true;
                this.employees[i].userName = data.userName;
                break;
            }
        }

        if (!isRepeat) {
            this.employees = this.employees.concat({
                _id: data._id,
                userName: data.userName,
            });
        }
    }
    onEmployeesRemove(data) {
        if (!this.iniLoadEmployee) return;

        for (let i = 0; i < this.employees.length; i++) {
            if (this.employees[i]._id == data) {
                this.employees.splice(i, 1);
                break;
            }
        }
    }
    onItemUpdate(data) {
        let isRepeat = false;

        if (!this.iniLoadItem) return;

        for (let i = 0; i < this.itemList.length; i++) {
            if (this.itemList[i].codeId == data.codeId) {
                isRepeat = true;
                this.itemList[i].itemName = data.itemName;
                this.itemList[i].itemNameS = data.itemNameS;
                this.itemList[i].unit = data.unit;
                this.itemList[i].cost = data.cost;
                break;
            }
        }

        if (!isRepeat) {
            this.itemList = this.itemList.concat({
                codeId: data.codeId,
                itemName: data.itemName,
                itemNameS: data.itemNameS,
                unit: data.unit,
                cost: data.cost,
            });
        }
    }
    onItemRemove(data) {
        if (!this.iniLoadItem) return;

        for (let i = 0; i < this.itemList.length; i++) {
            if (this.itemList[i].codeId == data) {
                this.itemList.splice(i, 1);
                break;
            }
        }
    }
    onIsNewCompany(data) {
        this.isNewCompany = data;
    }
    onOutletUpdate(data) {
        if (!this.iniLoadOutlet) return;

        this.outlets = this.outlets.concat({
            _id: data._id,
            outletName: data.outletName
        });

    }
    onShiftLabel(data) {
        this.shiftLabel = data;
    }
}
export default alt.createStore(filterStore, 'filterStore');