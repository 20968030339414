import alt from '../alt';

class userAction {
    constructor() {
        this.generateActions(
            'isUserLogin',
            'user',
            'warningMsg',
            'processing',
            'message',
        );
    }

    userLogin(email, pwd) {
       
        const xhttp = new XMLHttpRequest();
        xhttp.open("POST", '/v1/api/user/login', true);
        xhttp.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        xhttp.onreadystatechange = () => {
            if (xhttp.readyState == 4) {
                let result = JSON.parse(xhttp.responseText);
                if (xhttp.status == 200) {
                    sessionStorage.setItem("ui", result.token);
                    
                    this.user(result.user);
                    this.isUserLogin(true);
                } else if (xhttp.status == 400) {
                    //handle Error
                    this.warningMsg(false);
                    this.message("Email or Password Incorrect");
                    sessionStorage.removeItem("ui");
                }
                this.processing(false);
            }
        };
        xhttp.send(JSON.stringify({ email: email, password: pwd }));
        /*
        sessionStorage.setItem("ui", "ABC");
        this.isUserLogin(true);
        this.user({
            userId: "A001",
            userName: "Liang",
            permission: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        });
        this.processing(false);
        */
        return true;
    }

    isUserValid() {
        
        if (sessionStorage.getItem('ui')) {
            const xhttp = new XMLHttpRequest();
            xhttp.open("GET", '/v1/api/user', true);
            xhttp.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem('ui'));
            xhttp.onreadystatechange = () => {
                if (xhttp.readyState == 4) {
                    
                    if (xhttp.status == 200) {
                        let result = JSON.parse(xhttp.responseText);
                        this.isUserLogin(true);
                        this.user(result);
                    } else if (xhttp.status == 400) {
                        //handle Error
                        this.isUserLogin(false);
                        sessionStorage.removeItem("ui");
                    }
                    this.processing(false);
                }
            };
            xhttp.send(null);
        }
        /*
        this.isUserLogin(true);
        this.user({
            userId: "A001",
            userName: "Liang",
            permission: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        });
        this.processing(false);
        */
        return true;

    }
}

export default alt.createActions(userAction);