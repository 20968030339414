'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import menuAction from '../actions/menuAction';
import menuStore from '../stores/menuStore';
import PowerSettingIcon from '@material-ui/icons/PowerSettingsNew';
import languageAction from '../actions/languageAction';
import languageStore from '../stores/languageStore';
import userAction from '../actions/userAction';

const drawerWidth = 300;

const styles = theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: `translateZ(0px)`,

    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        transform: `translateZ(0px)`,
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 12,
    },
    hide: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        marginLeft: theme.spacing.unit * 2
    }
})
class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: menuStore.getState().open,
            hover: menuStore.getState().hover,
            languageSelection: languageStore.getState().languageSelection,
            lanIndex: languageStore.getState().lanIndex,
            language: languageStore.getState().language,
            anchorEl: null
        };
    }
    componentDidMount() {
        menuStore.listen(this.onChange);
        languageStore.listen(this.onLanguageChange);

        if (typeof window != 'undefined') {
            if (localStorage.getItem('lan')) {
                languageAction.lanIndex.defer(parseInt(localStorage.getItem('lan')));
            }
        }
    }
    componentWillUnmount() {
        menuStore.unlisten(this.onChange);
        languageStore.unlisten(this.onLanguageChange);
    }
    onChange = (state) => {
        this.setState({
            open: state.open,
            hover: state.hover,
        });
    };

    onLanguageChange = (state) => {
        this.setState({
            language: state.language,
            lanIndex: state.lanIndex
        });
    }
    handleDrawerOpen = () => {
        menuAction.open(true);
    };

    handleDrawerClose = () => {
        menuAction.open(false);
    };
    handleClick = (id) => (e) => {
        if (id == 1) {
            this.setState({ anchorEl: e.currentTarget });
        }else if(id == 2){
            userAction.isUserLogin(false);
            sessionStorage.removeItem('ui');
            window.location.href="/";
        }
    }
    handleLanClick = (index) => (e) => {
        if (this.state.lanIndex != parseInt(index)) {
            languageAction.lanIndex(parseInt(index));
            localStorage.setItem("lan", parseInt(index));
        }
        this.handleClose();
    }
    handleClose = () => {
        this.setState({ anchorEl: null });
    }
    renderLanguage = () => {
        return this.state.languageSelection.map((lg, index) => {
            return (
                <MenuItem key={index} onClick={this.handleLanClick(index)}>
                    {lg}
                </MenuItem>
            );
        })
    }
    render() {
        const { classes, theme, userName, companyName } = this.props;
        const { open, hover, languageSelection, lanIndex, anchorEl,language } = this.state;
        
        return (
            <div id="navbar">
                <AppBar
                    color="default"
                    position="fixed"
                    className={classNames(classes.appBar, (open || hover) && classes.appBarShift)}
                >
                    <Toolbar disableGutters>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerOpen}
                            className={classNames(classes.menuButton, (open || hover) && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                            {companyName}
                        </Typography>
                        <Typography variant="body1" color="inherit" noWrap style={{ marginRight: theme.spacing.unit }}>
                            {language[lanIndex].navWelcome}, {userName}
                        </Typography>
                        
                        <Button variant="text" mini onClick={this.handleClick(1)} color="inherit" >
                            {languageSelection[lanIndex]}
                        </Button>
                        <IconButton onClick={this.handleClick(2)} color="inherit" style={{ marginRight: theme.spacing.unit * 2 }}>
                            <PowerSettingIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Menu
                    id="language-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                    {this.renderLanguage()}
                </Menu>
            </div>
        )
    }
}

Navbar.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Navbar);