import alt from '../alt';
import menuAction from '../actions/menuAction';

class menuStore {
    constructor() {
        this.bindActions(menuAction);
        this.open = false;
        this.menuId = 0;
        this.hover = false;
        this.showDialog = false;
        this.warningMsg ="";
        this.snackbarShow = false;
        this.snackbarMessage = "";
    }
    onOpen(data){
        this.open = data;
    }
    onMenuId(data){
        this.menuId = data;
    }
    onHover(data){
        this.hover = data;
    }
    onWarningMsg(data){
        this.warningMsg = data;
    }
    onShowDialog(data){
        this.showDialog = data;
    }
    onSnackbarShow(data){
        this.snackbarShow = data;
    }
    onSnackbarMessage(data){
        this.snackbarMessage = data;
    }
}
export default alt.createStore(menuStore, 'menuStore');