import alt from '../alt';
import menuAction from './menuAction';

class filterAction {
    constructor() {
        this.generateActions(
            'employees',
            'employeesUpdate',
            'employeesRemove',
            'suppliers',
            'suppliersUpdate',
            'suppliersRemove',
            'iniLoadCategory',
            'categoryList',
            'categoryUpdate',
            'categoryUpdateList',
            'categoryRemove',
            'itemList',
            'itemUpdate',
            'itemRemove',
            'currencySymbol',
            'tax',
            'taxMode',
            'taxName',
            'company',
            'isNewCompany',
            'outlets',
            'outletUpdate',
            'unitList',
            'unitUpdate',
            'unitRemove',
            'payment',
            'customerGroup',
            'customerGrpUpdate',
            'customerGrpRemove',
            'customers',
            'customerUpdate',
            'customerRemove',
            'pricebookList',
            'shiftLabel',
            'paymentMethods',
            'iniLoadPaymentMethod',
            'deviceList',
            'groupList',
        );
    }
    getDeviceList() {
        if (sessionStorage.getItem('ui')) {
            const xhttp = new XMLHttpRequest();
            xhttp.open("GET", '/v1/api/deviceList', true);
            xhttp.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem('ui'));
            xhttp.onreadystatechange = () => {
                if (xhttp.readyState == 4) {
                    let result = JSON.parse(xhttp.responseText);
                    if (xhttp.status == 200) {
                        this.deviceList(result);
                    } else if (xhttp.status == 400) {
                        //handle Error
                        menuAction.showDialog(true);
                        menuAction.warningMsg(result.errorMsg);
                    }
                }
            };
            xhttp.send(null);
            return true;
        }
    }
    getPricebookList() {
        if (sessionStorage.getItem('ui')) {
            const xhttp = new XMLHttpRequest();
            xhttp.open("GET", '/v1/api/pricebook/promotion', true);
            xhttp.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem('ui'));
            xhttp.onreadystatechange = () => {
                if (xhttp.readyState == 4) {
                    let result = JSON.parse(xhttp.responseText);
                    if (xhttp.status == 200) {
                        this.pricebookList(result);
                    } else if (xhttp.status == 400) {
                        //handle Error
                        menuAction.showDialog(true);
                        menuAction.warningMsg(result.errorMsg);
                    }
                }
            };
            xhttp.send(null);
            return true;
        }
    }
    getCustomers() {
        if (sessionStorage.getItem('ui')) {
            const xhttp = new XMLHttpRequest();
            xhttp.open("GET", '/v1/api/customer/list', true);
            xhttp.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem('ui'));
            xhttp.onreadystatechange = () => {
                if (xhttp.readyState == 4) {
                    let result = JSON.parse(xhttp.responseText);
                    if (xhttp.status == 200) {
                        this.customers(result.customerList);
                    } else if (xhttp.status == 400) {
                        //handle Error
                        menuAction.showDialog(true);
                        menuAction.warningMsg(result.errorMsg);
                    }
                }
            };
            xhttp.send(null);
            return true;
        }
    }
    getCustomerGroup() {
        if (sessionStorage.getItem('ui')) {
            const xhttp = new XMLHttpRequest();
            xhttp.open("GET", '/v1/api/customerGroup', true);
            xhttp.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem('ui'));
            xhttp.onreadystatechange = () => {
                if (xhttp.readyState == 4) {
                    let result = JSON.parse(xhttp.responseText);
                    if (xhttp.status == 200) {
                        this.customerGroup(result);
                    } else if (xhttp.status == 400) {
                        //handle Error
                        menuAction.showDialog(true);
                        menuAction.warningMsg(result.errorMsg);
                    }
                }
            };
            xhttp.send(null);
            return true;
        }
    }
    getOutlets() {
        if (sessionStorage.getItem('ui')) {

            const xhttp = new XMLHttpRequest();
            xhttp.open("GET", '/v1/api/outlets', true);
            xhttp.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem('ui'));
            xhttp.onreadystatechange = () => {
                if (xhttp.readyState == 4) {
                    let result = JSON.parse(xhttp.responseText);
                    if (xhttp.status == 200) {
                        this.outlets(result);

                    } else if (xhttp.status == 400) {
                        //handle Error
                        menuAction.showDialog(true);
                        menuAction.warningMsg(result.errorMsg);
                    }
                }
            };
            xhttp.send(null);
            return true;
        }
    }
    getSuppliers() {
        if (sessionStorage.getItem('ui')) {

            const xhttp = new XMLHttpRequest();
            xhttp.open("GET", '/v1/api/suppliers', true);
            xhttp.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem('ui'));
            xhttp.onreadystatechange = () => {
                if (xhttp.readyState == 4) {
                    let result = JSON.parse(xhttp.responseText);
                    if (xhttp.status == 200) {
                        this.suppliers(result);

                    } else if (xhttp.status == 400) {
                        //handle Error
                        menuAction.showDialog(true);
                        menuAction.warningMsg(result.errorMsg);
                    }
                }
            };
            xhttp.send(null);

            /*
            this.suppliers([
                { supplierId: "SAP001", supplierName: "Supplier 01" },
                { supplierId: "SAP002", supplierName: "Supplier 02" },
                { supplierId: "SAP003", supplierName: "Supplier Chan Pte Ltd" },
                { supplierId: "SAP004", supplierName: "Supplier Fat Huang" },
                { supplierId: "SAP005", supplierName: "Supplier Xiao Mao" },
                { supplierId: "SAP006", supplierName: "Supplier ERT" },
                { supplierId: "SAP007", supplierName: "Supplier FFA" },
                { supplierId: "SAP008", supplierName: "Supplier RNG" },
            ]);
            */
        }

        return true;
    }
    getEmployees() {
        if (sessionStorage.getItem('ui')) {

            const xhttp = new XMLHttpRequest();
            xhttp.open("GET", '/v1/api/employees', true);
            xhttp.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem('ui'));
            xhttp.onreadystatechange = () => {
                if (xhttp.readyState == 4) {
                    let result = JSON.parse(xhttp.responseText);
                    if (xhttp.status == 200) {
                        this.employees(result);
                    } else if (xhttp.status == 400) {
                        //handle Error
                        menuAction.showDialog(true);
                        menuAction.warningMsg(result.errorMsg);
                    }
                }
            };
            xhttp.send(null);

            /*
            this.employees([
                { _id: "A001", userName: "Jack Ma" },
                { _id: "A002", userName: "Ah Fan" },
                { _id: "A003", userName: "Ah Meng" },
                { _id: "A004", userName: "Ah Gao" },
            ]);
            */
        }

        return true;
    }
    getUnitList() {
        if (sessionStorage.getItem('ui')) {

            const xhttp = new XMLHttpRequest();
            xhttp.open("GET", '/v1/api/unitList', true);
            xhttp.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem('ui'));
            xhttp.onreadystatechange = () => {
                if (xhttp.readyState == 4) {
                    let result = JSON.parse(xhttp.responseText);
                    if (xhttp.status == 200) {
                        this.unitList(result);
                    } else if (xhttp.status == 400) {
                        //handle Error
                        menuAction.showDialog(true);
                        menuAction.warningMsg(result.errorMsg);
                    }
                }
            };
            xhttp.send(null);

        }

        return true;
    }
    getGroupList(){
        if (sessionStorage.getItem('ui')) {

            const xhttp = new XMLHttpRequest();
            xhttp.open("GET", '/v1/api/groupList', true);
            xhttp.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem('ui'));
            xhttp.onreadystatechange = () => {
                if (xhttp.readyState == 4) {
                    let result = JSON.parse(xhttp.responseText);
                    if (xhttp.status == 200) {
                        this.groupList(result);
                    } else if (xhttp.status == 400) {
                        //handle Error
                        menuAction.showDialog(true);
                        menuAction.warningMsg(result.errorMsg);
                    }
                }
            };
            xhttp.send(null);

        }

        return true;
    }
    getCategoryList() {
        if (sessionStorage.getItem('ui')) {

            const xhttp = new XMLHttpRequest();
            xhttp.open("GET", '/v1/api/categoryList', true);
            xhttp.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem('ui'));
            xhttp.onreadystatechange = () => {
                if (xhttp.readyState == 4) {
                    let result = JSON.parse(xhttp.responseText);
                    if (xhttp.status == 200) {
                        this.categoryList(result);
                    } else if (xhttp.status == 400) {
                        //handle Error
                        menuAction.showDialog(true);
                        menuAction.warningMsg(result.errorMsg);
                    }
                }
            };
            xhttp.send(null);

        }

        return true;
    }
    getPaymentMethods() {
        if (sessionStorage.getItem('ui')) {
            const xhttp = new XMLHttpRequest();
            xhttp.open("GET", '/v1/api/transaction/paymentMethods', true);
            xhttp.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem('ui'));
            xhttp.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
            xhttp.onreadystatechange = () => {
                if (xhttp.readyState == 4) {
                    let result = JSON.parse(xhttp.responseText);
                    if (xhttp.status == 200) {
                        this.paymentMethods(result);
                    } else if (xhttp.status == 400) {
                        //handle Error
                        menuAction.showDialog(true);
                        menuAction.warningMsg(result.errorMsg);

                    }

                }
            };
            xhttp.send(null);
        }

        return true;
    }
    getItemList() {
        if (sessionStorage.getItem('ui')) {

            const xhttp = new XMLHttpRequest();
            xhttp.open("GET", '/v1/api/itemList', true);
            xhttp.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem('ui'));
            xhttp.onreadystatechange = () => {
                if (xhttp.readyState == 4) {
                    let result = JSON.parse(xhttp.responseText);
                    if (xhttp.status == 200) {
                        this.itemList(result);
                    } else if (xhttp.status == 400) {
                        //handle Error
                        menuAction.showDialog(true);
                        menuAction.warningMsg(result.errorMsg);
                    }
                }
            };
            xhttp.send(null);

            /*
            this.itemList([
                { codeId: "A001", itemName: "Coke", itemNameS: "Coke", unit: "can", cost: 0.3 },
                { codeId: "A002", itemName: "Sprite", itemNameS: "Sprite", unit: "can", cost: 0.3 },
                { codeId: "A003", itemName: "Yeo's", itemNameS: "Yeo's", unit: "can", cost: 0.3 },
                { codeId: "A004", itemName: "100 Plus", itemNameS: "100P", unit: "can", cost: 0.3 },
                { codeId: "A005", itemName: "Orange Juice", itemNameS: "Org Jce", unit: "can", cost: 0.3 },
                { codeId: "A006", itemName: "Apple Juice", itemNameS: "Aple Jce", unit: "can", cost: 0.3 },
                { codeId: "A007", itemName: "Herbal Tea", itemNameS: "Hrb Tea", unit: "can", cost: 0.3 },
                { codeId: "A008", itemName: "Milo", itemNameS: "Milo", unit: "can", cost: 0.3 },
                { codeId: "A009", itemName: "KiKaPo", itemNameS: "Ki'KPo", unit: "can", cost: 0.3 },
                { codeId: "A010", itemName: "Soya Milk", itemNameS: "Soya Mlk", unit: "can", cost: 0.3 },
            ]);
            */
        }

        return true;
    }
    getGeneralData() {
        if (sessionStorage.getItem('ui')) {

            const xhttp = new XMLHttpRequest();
            xhttp.open("GET", '/v1/api/general', true);
            xhttp.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem('ui'));
            xhttp.onreadystatechange = () => {
                if (xhttp.readyState == 4) {
                    let result = JSON.parse(xhttp.responseText);
                    if (xhttp.status == 200) {
                        this.company(result.company);
                        this.currencySymbol(result.system.currencySymbol);
                        this.isNewCompany(result.isNewCompany);
                        this.tax(result.tax.tax);
                        this.taxMode(result.tax.taxMode);
                        this.taxName(result.tax.taxName);
                        this.payment(result.payment);
                        this.shiftLabel(result.shiftLabel);
                    } else if (xhttp.status == 400) {
                        //handle Error
                        menuAction.showDialog(true);
                        menuAction.warningMsg(result.errorMsg);
                    }
                }
            };
            xhttp.send(null);
            /*
            this.company({ companyName: "Wang Wang Lai Pte Ltd" });
            this.currencySymbol("RM");
            this.tax(0.01);
            this.taxMode(0);
            this.taxName("SST");
            */
        }

        return true;
    }
}

export default alt.createActions(filterAction);