import React from 'react';
import Switch from 'react-router-dom/Switch';
import Route from 'react-router-dom/Route';
import Loadable from 'react-loadable';
import HomePage from './components/homepage';

const Login = Loadable({
    loader: () => import('./components/login'),
    loading: () => <div>loading</div>,
    
});



export default class App extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <Switch>
                <Route exact path="/" component={Login} />
                <Route path="/fnb" component={HomePage} />
            </Switch>
        );
    }
};

/*

</HomePage>
*/
