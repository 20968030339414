import React from 'react';
import ReactDOM from 'react-dom';
import BrowserRouter from 'react-router-dom/BrowserRouter';
import App from './app.jsx';
import Loadable from 'react-loadable';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import createGenerateClassName from '@material-ui/core/styles/createGenerateClassName';
import JssProvider from 'react-jss/lib/JssProvider';
import deepOrange from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
//import './styleSheets/chartStyle.scss';

class Main extends React.Component {
    // Remove the server-side injected CSS.
    componentDidMount() {
        const jssStyles = document.getElementById('jss-server-side');
        if (jssStyles && jssStyles.parentNode) {
            jssStyles.parentNode.removeChild(jssStyles);
        }

    }

    render() {
        return <App />;
    }
}

// Create a theme instance.
const theme = createMuiTheme({
    palette: {
        primary: deepOrange,
        accent: red,
        type: 'light',
    },
    typography: {
        useNextVariants: true,
    },
});


window.main = () => {
    
    // Create a new class name generator.
    const generateClassName = createGenerateClassName();
    Loadable.preloadReady().then(() => {
        ReactDOM.hydrate((
            <BrowserRouter>
                <JssProvider generateClassName={generateClassName}>
                    <MuiThemeProvider theme={theme}>
                        <Main />
                    </MuiThemeProvider>
                </JssProvider>
            </BrowserRouter>
        ), document.getElementById('root'));
    });
};