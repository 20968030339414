'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Navbar from './navbar';
import MenuDrawer from './menuDrawer';
import SnackBar from './snackBar';
import WarningDialog from './warningDialog';
import Route from 'react-router-dom/Route';
import menuStore from '../stores/menuStore';
import userAction from '../actions/userAction';
import userStore from '../stores/userStore';
import filterAction from '../actions/filterAction';
import filterStore from '../stores/filterStore';
import Loadable from 'react-loadable';


const styles = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 1,
        position: 'relative',

    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    /*
    drawerShow: {
        float: 'right',
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('sm')]: {
            width: `100%`
        },
    },
    */
    drawerHide: {
        float: 'right',
        width: `calc(100% - ${theme.spacing.unit * 9}px)`,
        [theme.breakpoints.down('sm')]: {
            width: `100%`
        },
    }

});

const Dashboard = Loadable({
    loader: () => import('./dashboard'),
    loading: () => <div>loading</div>
});
const SalesHourly = Loadable({
    loader: () => import('./salesHourly'),
    loading: () => <div>loading</div>
});
const SalesDaily = Loadable({
    loader: () => import('./salesDaily'),
    loading: () => <div>loading</div>
});
const SalesMonthly = Loadable({
    loader: () => import('./salesMonthly'),
    loading: () => <div>loading</div>
});
const SalesYearly = Loadable({
    loader: () => import('./salesYearly'),
    loading: () => <div>loading</div>
});
const SalesItem = Loadable({
    loader: () => import('./salesItem'),
    loading: () => <div>loading</div>
});
const EventAdd = Loadable({
    loader: () => import('./eventAdd'),
    loading: () => <div>loading</div>
});
const EventList = Loadable({
    loader: () => import('./eventList'),
    loading: () => <div>loading</div>
});
const ItemAdd = Loadable({
    loader: () => import('./itemAdd'),
    loading: () => <div>loading</div>
});
const ItemMenu = Loadable({
    loader: () => import('./itemMenu'),
    loading: () => <div>loading</div>
});
const StockIn = Loadable({
    loader: () => import('./stockIn'),
    loading: () => <div>loading</div>
});
const StockInAdd = Loadable({
    loader: () => import('./stockInAdd'),
    loading: () => <div>loading</div>
});
const StockOut = Loadable({
    loader: () => import('./stockOut'),
    loading: () => <div>loading</div>
});
const StockOutAdd = Loadable({
    loader: () => import('./stockOutAdd'),
    loading: () => <div>loading</div>
});
const Supplier = Loadable({
    loader: () => import('./supplier'),
    loading: () => <div>loading</div>
});
const SupplierAdd = Loadable({
    loader: () => import('./supplierAdd'),
    loading: () => <div>loading</div>
});
const Receive = Loadable({
    loader: () => import('./receive'),
    loading: () => <div>loading</div>
});
const ReceiveAdd = Loadable({
    loader: () => import('./receiveAdd'),
    loading: () => <div>loading</div>
});
const StockAdj = Loadable({
    loader: () => import('./stockAdj'),
    loading: () => <div>loading</div>
});
const StockAdjAdd = Loadable({
    loader: () => import('./stockAdjAdd'),
    loading: () => <div>loading</div>
});
const StockTake = Loadable({
    loader: () => import('./stockTake'),
    loading: () => <div>loading</div>
});
const StockTakeAdd = Loadable({
    loader: () => import('./stockTakeAdd'),
    loading: () => <div>loading</div>
});
const StockTakeComplete = Loadable({
    loader: () => import('./stockTakeComplete'),
    loading: () => <div>loading</div>
});
const TableAdd = Loadable({
    loader: () => import('./tableAdd'),
    loading: () => <div>loading</div>
});
/*
const Table = Loadable({
    loader: () => import('./table'),
    loading: () => <div>loading</div>
});
*/
const EmployeeAdd = Loadable({
    loader: () => import('./employeeAdd'),
    loading: () => <div>loading</div>
});
const Employee = Loadable({
    loader: () => import('./employee'),
    loading: () => <div>loading</div>
});
const Company = Loadable({
    loader: () => import('./company'),
    loading: () => <div>loading</div>
});
const SystemSetup = Loadable({
    loader: () => import('./systemSetup'),
    loading: () => <div>loading</div>
});
const Reason = Loadable({
    loader: () => import('./reason'),
    loading: () => <div>loading</div>
});
const AccessControl = Loadable({
    loader: () => import('./accessControl'),
    loading: () => <div>loading</div>
});
const Printer = Loadable({
    loader: () => import('./printer'),
    loading: () => <div>loading</div>
});
const Transaction = Loadable({
    loader: () => import('./transaction'),
    loading: () => <div>loading</div>
});
const TransDetail = Loadable({
    loader: () => import('./transDetail'),
    loading: () => <div>loading</div>
});
const Device = Loadable({
    loader: () => import('./device'),
    loading: () => <div>loading</div>
});
const ItemReset = Loadable({
    loader: () => import('./itemReset'),
    loading: () => <div>loading</div>
});


const Shift = Loadable({
    loader: () => import('./shift'),
    loading: () => <div>loading</div>
});
const ShiftDetail = Loadable({
    loader: () => import('./shiftDetail'),
    loading: () => <div>loading</div>
});
const Attend = Loadable({
    loader: () => import('./attend'),
    loading: () => <div>loading</div>
});


const Outlet = Loadable({
    loader: () => import('./outlet'),
    loading: () => <div>loading</div>
});
const OutletAdd = Loadable({
    loader: () => import('./outletAdd'),
    loading: () => <div>loading</div>
});
const AttendSetup = Loadable({
    loader: () => import('./attendSetup'),
    loading: () => <div>loading</div>
});

const SalesOutlet = Loadable({
    loader: () => import('./salesOutlet'),
    loading: () => <div>loading</div>
});
const SalesTag = Loadable({
    loader: () => import('./salesTag'),
    loading: () => <div>loading</div>
});
const SalesItemDaily = Loadable({
    loader: () => import('./salesItemDaily'),
    loading: () => <div>loading</div>
});
const DatabaseControl = Loadable({
    loader: () => import('./databaseControl'),
    loading: () => <div>loading</div>
});

const StockTransferAdd = Loadable({
    loader: () => import('./stockTransferAdd'),
    loading: () => <div>loading</div>
});
const StockTransfer = Loadable({
    loader: () => import('./stockTransfer'),
    loading: () => <div>loading</div>
});
const LowStockItems = Loadable({
    loader: () => import('./lowStockItem'),
    loading: () => <div>loading</div>
});
const StockPurchase = Loadable({
    loader: () => import('./stockPurchase'),
    loading: () => <div>loading</div>
});
const StockPurchaseAdd = Loadable({
    loader: () => import('./stockPurchaseAdd'),
    loading: () => <div>loading</div>
});
const CustomerDisplay = Loadable({
    loader: () => import('./customerDisplay'),
    loading: () => <div>loading</div>
});
const CustomerDisplayAdd = Loadable({
    loader: () => import('./customerDisplayAdd'),
    loading: () => <div>loading</div>
});
const ItemModifier = Loadable({
    loader: () => import('./itemModifier'),
    loading: () => <div>loading</div>
});
const Performance = Loadable({
    loader: () => import('./performance'),
    loading: () => <div>loading</div>
});
const SelfOrder = Loadable({
    loader: () => import('./selfOrder'),
    loading: () => <div>loading</div>
});
const SelfOrderQr = Loadable({
    loader: () => import('./selfOrderQr'),
    loading: () => <div>loading</div>
});
const Order = Loadable({
    loader: () => import('./order'),
    loading: () => <div>loading</div>
});
const OrderDetail = Loadable({
    loader: () => import('./orderDetail'),
    loading: () => <div>loading</div>
});
const EmployeeMeal = Loadable({
    loader: () => import('./employeeMeal'),
    loading: () => <div>loading</div>
});
const StaffMeal = Loadable({
    loader: () => import('./staffMeal'),
    loading: () => <div>loading</div>
});
const StaffMealAdd = Loadable({
    loader: () => import('./staffMealAdd'),
    loading: () => <div>loading</div>
});

const performanceOverall = Loadable({
    loader: () => import('./performanceOverall'),
    loading: () => <div>loading</div>
});
const salesCustomer = Loadable({
    loader: () => import('./salesCustomer'),
    loading: () => <div>loading</div>
});
const stockIngredient = Loadable({
    loader: () => import('./stockIngredient'),
    loading: () => <div>loading</div>
});
const Audit = Loadable({
    loader: () => import('./Audit'),
    loading: () => <div>loading</div>
});
const ReserveSetting = Loadable({
    loader: () => import('./ReserveSetting'),
    loading: () => <div>loading</div>
});
const Unit = Loadable({
    loader: () => import('./itemUnit'),
    loading: () => <div>loading</div>
});
const Pricebook = Loadable({
    loader: () => import('./priceBook'),
    loading: () => <div>loading</div>
});
const PricebookAdd = Loadable({
    loader: () => import('./priceBookAdd'),
    loading: () => <div>loading</div>
});
const stockBalance = Loadable({
    loader: () => import('./stockBalance'),
    loading: () => <div>loading</div>
});
const CustomerGroup = Loadable({
    loader: () => import('./customerGroup'),
    loading: () => <div>loading</div>
});
const Customer = Loadable({
    loader: () => import('./customer'),
    loading: () => <div>loading</div>
});
const Reservation = Loadable({
    loader: () => import('./Reservation'),
    loading: () => <div>loading</div>
});
const SerialNumber = Loadable({
    loader: () => import('./serialNumber'),
    loading: () => <div>loading</div>
});
const CustomerDetail = Loadable({
    loader: () => import('./customerDetail'),
    loading: () => <div>loading</div>
});

const SalesOverallDaily = Loadable({
    loader: () => import('./salesOverallDaily'),
    loading: () => <div>loading</div>
});
const MummyReturn = Loadable({
    loader: () => import('./mummyReturn'),
    loading: () => <div>loading</div>
});
const SalesItemSummary = Loadable({
    loader: () => import('./salesItemSummary'),
    loading: () => <div>loading</div>
});
const PerformanceDaily = Loadable({
    loader: () => import('./performanceDaily'),
    loading: () => <div>loading</div>
});
const PerformanceSinger = Loadable({
    loader: () => import('./performanceSinger'),
    loading: () => <div>loading</div>
});
const PerformanceSingerDetail = Loadable({
    loader: () => import('./performanceSingerDetail'),
    loading: () => <div>loading</div>
});
const RestaurantSetting = Loadable({
    loader: () => import('./RestaurantSetting'),
    loading: () => <div>loading</div>
});
const Category = Loadable({
    loader: () => import('./itemCategory'),
    loading: () => <div>loading</div>
});
const CostOfGoodSale = Loadable({
    loader: () => import('./costOfGoodSale'),
    loading: () => <div>loading</div>
});
const EmployeeCom = Loadable({
    loader: () => import('./employeeCom'),
    loading: () => <div>loading</div>
});
const SalesSystem = Loadable({
    loader: () => import('./salesSystem'),
    loading: () => <div>loading</div>
});

const SingersShift = Loadable({
    loader: () => import('./singersShift'),
    loading: () => <div>loading</div>
});
const SingerOverall = Loadable({
    loader: () => import('./singerOverall'),
    loading: () => <div>loading</div>
});
const SingerDetail = Loadable({
    loader: () => import('./singerDetail'),
    loading: () => <div>loading</div>
});
const HoldReturn = Loadable({
    loader: () => import('./holdReturn'),
    loading: () => <div>loading</div>
});
const SingerAdd = Loadable({
    loader: () => import('./singerAdd'),
    loading: () => <div>loading</div>
});
const TableSetup = Loadable({
    loader: () => import('./TableSetting'),
    loading: () => <div>loading</div>
});

const CommSetup = Loadable({
    loader: () => import('./CommSetup'),
    loading: () => <div>loading</div>
});
const CustomerSetting = Loadable({
    loader: () => import('./customerSetting'),
    loading: () => <div>loading</div>
});
const CustomerReward = Loadable({
    loader: () => import('./CustomerReward'),
    loading: () => <div>loading</div>
});
const CustomerRewardAdd = Loadable({
    loader: () => import('./CustomerRewardAdd'),
    loading: () => <div>loading</div>
});
const StockInOut = Loadable({
    loader: () => import('./StockInOut'),
    loading: () => <div>loading</div>
});

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: menuStore.getState().open,
            isUserLogin: userStore.getState().isUserLogin,
            user: userStore.getState().user,
            company: filterStore.getState().company,
            iniLoadGeneral: filterStore.getState().iniLoadGeneral,
        };
    }

    componentWillMount() {

    }
    componentDidMount() {
        if (typeof window != 'undefined') {
            if (!sessionStorage.getItem('ui')) {
                window.location.href = '/';
            } else if (!this.state.isUserLogin) {
                userAction.isUserValid();
            }
        }
        menuStore.listen(this.onChange);
        userStore.listen(this.onUserChange);
        filterStore.listen(this.onFilterChange);
        if (!this.state.iniLoadGeneral) {
            filterAction.getGeneralData.defer();
        }
    }
    componentWillUnmount() {
        menuStore.unlisten(this.onChange);
        userStore.unlisten(this.onUserChange);
        filterStore.unlisten(this.onFilterChange);
    }
    componentDidUpdate(prevState, prevProps) {
        if (!sessionStorage.getItem('ui')) {
            window.location.href = '/';
        }
    }
    onChange = (state) => {
        this.setState({
            open: state.open,
        });
    };
    onUserChange = (state) => {
        this.setState({
            isUserLogin: state.isUserLogin,
            user: state.user,
        })
    }
    onFilterChange = (state) => {
        this.setState({
            company: state.company,
            iniLoadGeneral: state.iniLoadGeneral,
        });
    }
    render() {
        const { classes, history, match } = this.props;
        const { open, user, company } = this.state;
        return (
            <div className={classes.root}>
                <Navbar history={history} userName={user.userName} companyName={company.companyName} />
                <MenuDrawer history={history} user={user} />
                <div className={classes.drawerHide}>
                    <div className={classNames(classes.content, "hm-content")}>
                        <div className={classNames(classes.toolbar, "hm-toolbar")} />

                        <Route exact path={`${match.url}/dashboard`} component={Dashboard} />


                        <Route exact path={`${match.url}/hourlySales`} component={SalesHourly} />
                        <Route exact path={`${match.url}/dailySales`} component={SalesDaily} />
                        <Route exact path={`${match.url}/monthlySales`} component={SalesMonthly} />
                        <Route exact path={`${match.url}/yearlySales`} component={SalesYearly} />
                        <Route exact path={`${match.url}/itemSales`} component={SalesItem} />
                        <Route exact path={`${match.url}/dailyItemSales`} component={SalesItemDaily} />


                        <Route exact path={`${match.url}/shift`} component={Shift} />
                        <Route exact path={`${match.url}/shiftDetail`} component={ShiftDetail} />


                        <Route exact path={`${match.url}/addPromotion`} component={EventAdd} />
                        <Route exact path={`${match.url}/promotionList`} component={EventList} />


                        <Route exact path={`${match.url}/addProduct`} component={ItemAdd} />
                        <Route exact path={`${match.url}/products`} component={ItemMenu} />
                        <Route exact path={`${match.url}/productQtyReset`} component={ItemReset} />

                        <Route exact path={`${match.url}/supplier`} component={Supplier} />
                        <Route exact path={`${match.url}/addSupplier`} component={SupplierAdd} />

                        <Route exact path={`${match.url}/receive`} component={Receive} />
                        <Route exact path={`${match.url}/newReceive`} component={ReceiveAdd} />
                        <Route exact path={`${match.url}/newReceive/:id`} component={ReceiveAdd} />


                        <Route exact path={`${match.url}/stockIn`} component={StockIn} />
                        <Route exact path={`${match.url}/newStockIn`} component={StockInAdd} />
                        <Route exact path={`${match.url}/stockAdj`} component={StockAdj} />
                        <Route exact path={`${match.url}/newStockAdj`} component={StockAdjAdd} />


                        <Route exact path={`${match.url}/stockOut`} component={StockOut} />
                        <Route exact path={`${match.url}/newStockOut`} component={StockOutAdd} />

                        <Route exact path={`${match.url}/stockTake`} component={StockTake} />
                        <Route exact path={`${match.url}/stockTake/count`} component={StockTakeAdd} />
                        <Route exact path={`${match.url}/stockTake/complete`} component={StockTakeComplete} />

                        <Route exact path={`${match.url}/addTable`} component={TableAdd} />

                        <Route exact path={`${match.url}/employee`} component={Employee} />
                        <Route exact path={`${match.url}/addEmployee`} component={EmployeeAdd} />

                        <Route exact path={`${match.url}/companySetup`} component={Company} />
                        <Route exact path={`${match.url}/systemSetup`} component={SystemSetup} />
                        <Route exact path={`${match.url}/reasonPreset`} component={Reason} />
                        <Route exact path={`${match.url}/printerSetup`} component={Printer} />
                        <Route exact path={`${match.url}/deviceConnection`} component={Device} />
                        <Route exact path={`${match.url}/database`} component={DatabaseControl} />
                        <Route exact path={`${match.url}/attendSetup`} component={AttendSetup} />

                        <Route exact path={`${match.url}/accessControl`} component={AccessControl} />

                        <Route exact path={`${match.url}/transaction`} component={Transaction} />
                        <Route exact path={`${match.url}/transactionDetail/:outletId`} component={TransDetail} />
                        <Route exact path={`${match.url}/transactionDetail`} component={TransDetail} />

                        <Route exact path={`${match.url}/attend`} component={Attend} />
                        <Route exact path={`${match.url}/outletSales`} component={SalesOutlet} />
                        <Route exact path={`${match.url}/tagSales`} component={SalesTag} />
                        <Route exact path={`${match.url}/outlet`} component={Outlet} />
                        <Route exact path={`${match.url}/outletAdd`} component={OutletAdd} />
                        <Route exact path={`${match.url}/stockTransferAdd`} component={StockTransferAdd} />
                        <Route exact path={`${match.url}/stockTransferAdd/:id`} component={StockTransferAdd} />
                        <Route exact path={`${match.url}/stockTransfer`} component={StockTransfer} />
                        <Route exact path={`${match.url}/lowStockItems`} component={LowStockItems} />
                        <Route exact path={`${match.url}/purchaseOrder`} component={StockPurchase} />
                        <Route exact path={`${match.url}/newPurchaseOrder`} component={StockPurchaseAdd} />
                        <Route exact path={`${match.url}/newPurchaseOrder/:id`} component={StockPurchaseAdd} />
                        <Route exact path={`${match.url}/display`} component={CustomerDisplay} />
                        <Route exact path={`${match.url}/displayDetail`} component={CustomerDisplayAdd} />
                        <Route exact path={`${match.url}/displayDetail/:id`} component={CustomerDisplayAdd} />
                        <Route exact path={`${match.url}/modifier`} component={ItemModifier} />
                        <Route exact path={`${match.url}/performance`} component={Performance} />
                        <Route exact path={`${match.url}/selfOrder`} component={SelfOrder} />
                        <Route exact path={`${match.url}/selfOrderQr`} component={SelfOrderQr} />
                        <Route exact path={`${match.url}/order`} component={Order} />
                        <Route exact path={`${match.url}/orderDetail/:outletId`} component={OrderDetail} />
                        <Route exact path={`${match.url}/employee/staffMeal`} component={EmployeeMeal} />
                        <Route exact path={`${match.url}/staffMeal`} component={StaffMeal} />
                        <Route exact path={`${match.url}/staffMealAdd`} component={StaffMealAdd} />
                        <Route exact path={`${match.url}/performanceOverall`} component={performanceOverall} />
                        <Route exact path={`${match.url}/customerSales`} component={salesCustomer} />
                        <Route exact path={`${match.url}/ingredient`} component={stockIngredient} />
                        <Route exact path={`${match.url}/auditTrail`} component={Audit} />
                        <Route exact path={`${match.url}/reserveSetting`} component={ReserveSetting} />
                        <Route exact path={`${match.url}/unit`} component={Unit} />
                        <Route exact path={`${match.url}/pricebook`} component={Pricebook} />
                        <Route exact path={`${match.url}/pricebook/add/:id`} component={PricebookAdd} />
                        <Route exact path={`${match.url}/stockBalance`} component={stockBalance} />
                        <Route exact path={`${match.url}/customerGroup`} component={CustomerGroup} />
                        <Route exact path={`${match.url}/customer`} component={Customer} />
                        <Route exact path={`${match.url}/reserve`} component={Reservation} />
                        <Route exact path={`${match.url}/serialNumber`} component={SerialNumber} />
                        <Route exact path={`${match.url}/customerDetail/:id`} component={CustomerDetail} />
                        <Route exact path={`${match.url}/dailyOverallSales`} component={SalesOverallDaily} />
                        <Route exact path={`${match.url}/mummyReturn`} component={MummyReturn} />
                        <Route exact path={`${match.url}/itemSalesSummary`} component={SalesItemSummary} />
                        <Route exact path={`${match.url}/performance/daily`} component={PerformanceDaily} />
                        <Route exact path={`${match.url}/performance/singer`} component={PerformanceSinger} />
                        <Route exact path={`${match.url}/performance/singer/detail`} component={PerformanceSingerDetail} />
                        <Route exact path={`${match.url}/restaurantSetup`} component={RestaurantSetting} />
                        <Route exact path={`${match.url}/category`} component={Category} />
                        <Route exact path={`${match.url}/cogs`} component={CostOfGoodSale} />
                        <Route exact path={`${match.url}/employeeCom`} component={EmployeeCom} />
                        <Route exact path={`${match.url}/SalesSystemSummary`} component={SalesSystem} />
                        <Route exact path={`${match.url}/singerShift`} component={SingersShift} />
                        <Route exact path={`${match.url}/singerOverall`} component={SingerOverall} />
                        <Route exact path={`${match.url}/singer/detail`} component={SingerDetail} />
                        <Route exact path={`${match.url}/holdReturn`} component={HoldReturn} />
                        <Route exact path={`${match.url}/tableSetup`} component={TableSetup} />
                        <Route exact path={`${match.url}/addSinger`} component={SingerAdd} />
                        <Route exact path={`${match.url}/commSetup`} component={CommSetup} />
                        <Route exact path={`${match.url}/customerReward`} component={CustomerReward} />
                        <Route exact path={`${match.url}/customerRewardAdd`} component={CustomerRewardAdd} />
                        <Route exact path={`${match.url}/customerSetting`} component={CustomerSetting} />
                        <Route exact path={`${match.url}/stockInOut`} component={StockInOut} />

                    </div>
                    <SnackBar />
                    <WarningDialog />
                </div>
            </div>
        );

    }
}

HomePage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(HomePage);