import alt from '../alt';
import userAction from '../actions/userAction';

class userStore {
    constructor() {
        this.bindActions(userAction);
        this.isUserLogin = false;
        this.processing = false;
        this.warningMsg = false;
        this.message="";
        this.user = {
            userId: "",
            userName: "",
            permission: [],
            companyId:""
        }
    }

    onIsUserLogin(data) {
        this.isUserLogin = data;
    }
    onUser(data) {
        this.user = data;
    }
    onWarningMsg(data){
        this.warningMsg = data;
    }
    onProcessing(data){
        this.processing = data;
    }
    onMessage(data){
        this.message = data;
    }
}
export default alt.createStore(userStore, 'userStore');