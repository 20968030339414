'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import ShiftIcon from '@material-ui/icons/Receipt';
import amber from '@material-ui/core/colors/amber';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarchartIcon from '@material-ui/icons/BarChart';
import TransIcon from '@material-ui/icons/Assignment';
import LocalGroceryIcon from '@material-ui/icons/LocalGroceryStore';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import CakeIcon from '@material-ui/icons/Cake';
import PeopleIcon from '@material-ui/icons/PermContactCalendar';
import ReserveIcon from '@material-ui/icons/Today';
import DesktopIcon from '@material-ui/icons/DesktopWindows';
import MemberIcon from '@material-ui/icons/CardMembership';
import SvgIcon from '@material-ui/core/SvgIcon';
import StaffMealIcon from '@material-ui/icons/LocalCafe';
import AuditIcon from '@material-ui/icons/Feedback';

import menuAction from '../../actions/menuAction';
import menuStore from '../../stores/menuStore';
import languageStore from '../../stores/languageStore';
const styles = theme => ({
    active: {
        backgroundColor: amber[500],
        '&:hover': {
            backgroundColor: amber[600],
        }
    },
    inactive: {
        '&:hover': {
            backgroundColor: amber[600],
        }
    },
    listItemTxt: {
        transition: theme.transitions.create(['opacity'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        //opacity: 1,
        color: 'white',

    },
    listItemClose: {
        transition: theme.transitions.create(['opacity'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        //opacity: 0
    },
    listIconTxt: {
        marginRight: theme.spacing.unit * 2,
        width: '24px',
        color: 'white'
    },
    listIcon: {
        color: 'white'
    }
});


class DrawerContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuId: menuStore.getState().menuId,
            lanIndex: languageStore.getState().lanIndex,
            language: languageStore.getState().language,
            drawer: []
        };
    }

    componentDidMount() {
        menuStore.listen(this.onChange);
        languageStore.listen(this.onLangaugeChange);
        this.updateDrawer(this.state.lanIndex);
    }
    componentWillUnmount() {
        menuStore.unlisten(this.onChange);
        languageStore.unlisten(this.onLangaugeChange);
    }
    componentWillUpdate(prevProps, prevState) {
        if (prevProps.open != this.props.open ||
            prevProps.hover != this.props.hover ||
            prevState.menuId != this.state.menuId ||
            prevState.lanIndex != this.state.lanIndex
        ) {
            return true;
        }

        return false;
    }
    onChange = (state) => {
        this.setState({
            menuId: state.menuId
        });
    };
    updateDrawer = (lanIndex) => {
        let lan = this.state.language[lanIndex];
        //latest 45
        this.setState({
            drawer: [
                { menuId: 0, primaryTitle: lan.menuDashBoard, hasSecondary: false, secondaryContent: [], permission: [20] },
                { menuId: 35, primaryTitle: lan.menuTransaction, hasSecondary: false, secondaryContent: [], permission: [21] },
                { menuId: 55, primaryTitle: lan.menuOrder, hasSecondary: false, secondaryContent: [], permission: [21] },
                { menuId: 40, primaryTitle: lan.menuShift, hasSecondary: false, secondaryContent: [], permission: [24] },
                {
                    menuId: 1, primaryTitle: lan.menuSalesReport, hasSecondary: true, shouldExpand: false, permission: [10],
                    secondaryContent: [
                        { menuId: 60, secondaryTitle: lan.menuSalesCustomer, iconTitle: "CS", permission: 10 },
                        { menuId: 2, secondaryTitle: lan.menuSalesReportHourly, iconTitle: "HS", permission: 10 },
                        { menuId: 27, secondaryTitle: lan.menuSalesReportDaily, iconTitle: "DS", permission: 10 },
                        { menuId: 3, secondaryTitle: lan.menuSalesReportMonthly, iconTitle: "MS", permission: 10 },
                        { menuId: 4, secondaryTitle: lan.menuSalesReportYearly, iconTitle: "YS", permission: 10 },
                        { menuId: 37, secondaryTitle: lan.menuSalesReportItemDaily, iconTitle: "DI", permission: 10 },
                        { menuId: 5, secondaryTitle: lan.menuSalesReportItem, iconTitle: "MI", permission: 10 },
                        { menuId: 44, secondaryTitle: lan.menuSalesReportOutlet, iconTitle: "OT", permission: 10 },
                        { menuId: 45, secondaryTitle: lan.menuSalesReportTag, iconTitle: "TG", permission: 10 },
                        { menuId: 74, secondaryTitle: lan.menuSalesReportDailyOverall, iconTitle: "DA", permission: 10 },
                        { menuId: 76, secondaryTitle: lan.menuSalesReportItemCheck, iconTitle: "IC", permission: 10 },
                        { menuId: 81, secondaryTitle: "COGS", iconTitle: "CG", permission: 10 },
                        { menuId: 83, secondaryTitle: lan.menuSalesSystem, iconTitle: "SS", permission: 10 },

                    ]
                },
                {
                    menuId: 6, primaryTitle: lan.menuProduct, hasSecondary: true, shouldExpand: false, permission: [15],
                    secondaryContent: [
                        { menuId: 11, secondaryTitle: lan.menuProductList, iconTitle: "PL", permission: 15 },
                        { menuId: 7, secondaryTitle: lan.menuAddProduct, iconTitle: "AP", permission: 15 },
                        { menuId: 67, secondaryTitle: lan.menuPriceBook, iconTitle: "PB", permission: 15 },
                        { menuId: 73, secondaryTitle: lan.menuSerialNumber, iconTitle: "SN", permission: 15 },
                        { menuId: 50, secondaryTitle: lan.menuProductModifier, iconTitle: "MD", permission: 15 },
                        { menuId: 66, secondaryTitle: lan.itemUnitAdd, iconTitle: "UT", permission: 15 },
                        { menuId: 80, secondaryTitle: lan.salesCategory, iconTitle: "SC", permission: 15 },

                    ]
                },
                {
                    menuId: 136, primaryTitle: lan.menuCustomer, hasSecondary: true, shouldExpand: false, permission: [34],
                    secondaryContent: [
                        { menuId: 137, secondaryTitle: lan.menuCustomerList, iconTitle: "CL", permission: 34 },
                        { menuId: 138, secondaryTitle: lan.menuCustomerGroup, iconTitle: "CG", permission: 34 },
                        { menuId: 139, secondaryTitle: lan.menuCustomerReward, iconTitle: "CR", permission: 34 },

                    ]
                },
                {
                    menuId: 12, primaryTitle: lan.menuStockManagement, hasSecondary: true, shouldExpand: false, permission: [11, 12, 13, 14, 23, 27, 28, 29, 39],
                    secondaryContent: [
                        { menuId: 68, secondaryTitle: lan.menuStockBalance, iconTitle: "SB", permission: 39 },
                        { menuId: 142, secondaryTitle: "Stock In/Out Summary", iconTitle: "SS", permission: 39 },

                        { menuId: 47, secondaryTitle: lan.stockLowItems, iconTitle: "LS", permission: 39 },
                        { menuId: 61, secondaryTitle: lan.menuIngredient, iconTitle: "IS", permission: 39 },
                        { menuId: 38, secondaryTitle: lan.menuStockAdj, iconTitle: "SA", permission: 27 },
                        { menuId: 8, secondaryTitle: lan.menuStockIn, iconTitle: "SI", permission: 11 },
                        { menuId: 13, secondaryTitle: lan.menuStockTake, iconTitle: "SS", permission: 14 },
                        { menuId: 14, secondaryTitle: lan.menuStockOut, iconTitle: "SO", permission: 12 },
                        { menuId: 10, secondaryTitle: lan.menuReceive, iconTitle: "SR", permission: 13 },
                        { menuId: 46, secondaryTitle: lan.menuStockTransfer, iconTitle: "ST", permission: 28 },
                        { menuId: 48, secondaryTitle: lan.menuStockPurchase, iconTitle: "SP", permission: 29 },
                        { menuId: 9, secondaryTitle: lan.menuSupplier, iconTitle: "SP", permission: 23 },


                    ]
                },
                {
                    menuId: 24, primaryTitle: lan.menuPromotion, hasSecondary: true, shouldExpand: false, permission: [19],
                    secondaryContent: [
                        { menuId: 25, secondaryTitle: lan.menuPromotionAdd, iconTitle: "AP", permission: 19 },
                        { menuId: 26, secondaryTitle: lan.menuPromotionList, iconTitle: "PL", permission: 19 },
                        { menuId: 49, secondaryTitle: lan.menuCustomerDisplay, iconTitle: "CD", permission: 19 },

                    ]
                },

                {
                    menuId: 72, primaryTitle: lan.menuReservation, hasSecondary: false, shouldExpand: false, permission: [35],
                    secondaryContent: [
                    ]
                },
                {
                    menuId: 57, primaryTitle: lan.menuStaffMealSetup, hasSecondary: true, shouldExpand: false, permission: [32],
                    secondaryContent: [
                        { menuId: 58, secondaryTitle: lan.menuStaffMealList, iconTitle: "SL", permission: 32 },
                        { menuId: 59, secondaryTitle: lan.menuStaffMealAdd, iconTitle: "SA", permission: 32 },
                    ]
                },
                {
                    menuId: 52, primaryTitle: lan.menuSelfOrder, hasSecondary: true, shouldExpand: false, permission: [31],
                    secondaryContent: [
                        { menuId: 53, secondaryTitle: lan.menuSelfOrderSetup, iconTitle: "SO", permission: 31 },
                        { menuId: 54, secondaryTitle: lan.menuSelfOrderQr, iconTitle: "QR", permission: 31 },
                    ]
                },
                {
                    menuId: 16, primaryTitle: lan.menuTable, hasSecondary: false, shouldExpand: false, permission: [22],
                    /*
                    secondaryContent: [
                        { menuId: 17, secondaryTitle: lan.menuTableAdd, iconTitle: "AT", permission: 22 },
                        { menuId: 18, secondaryTitle: lan.menuTableList, iconTitle: "TL", permission: 22 }
                    ]
                    */
                },
                {
                    menuId: 19, primaryTitle: lan.menuEmployee, hasSecondary: true, shouldExpand: false, permission: [16, 18, 24],
                    secondaryContent: [

                        { menuId: 51, secondaryTitle: lan.menuEmployeePerformance, iconTitle: "EA", permission: 18 },
                        { menuId: 77, secondaryTitle: lan.menuEmployeePerformanceDaily, iconTitle: "EA", permission: 18 },
                        { menuId: 82, secondaryTitle: "Employee Com", iconTitle: "EC", permission: 18 },
                        // { menuId: 78, secondaryTitle: lan.menuSingerPerformance, iconTitle: "SP", permission: 18 },
                        { menuId: 84, secondaryTitle: lan.menuSingerShift, iconTitle: "SS", permission: 18 },
                        { menuId: 85, secondaryTitle: lan.menuSingerOveral, iconTitle: "ST", permission: 18 },
                        { menuId: 87, secondaryTitle: lan.menuSingerDetail, iconTitle: "SD", permission: 18 },
                        { menuId: 42, secondaryTitle: lan.menuAttendance, iconTitle: "AL", permission: 24 },
                        { menuId: 56, secondaryTitle: lan.menuStaffMeal, iconTitle: "SM", permission: 16 },
                        { menuId: 75, secondaryTitle: lan.menuMummyReturn, iconTitle: "MR", permission: 18 },
                        { menuId: 88, secondaryTitle: "Hold Bill Return", iconTitle: "HR", permission: 18 },
                        { menuId: 20, secondaryTitle: lan.menuEmployeeAdd, iconTitle: "AE", permission: 16 },
                        { menuId: 89, secondaryTitle: "Add Singer", iconTitle: "AS", permission: 16 },

                        { menuId: 21, secondaryTitle: lan.menuEmployees, iconTitle: "EM", permission: 16 },
                        { menuId: 23, secondaryTitle: lan.menuEmployeeAccess, iconTitle: "EA", permission: 18 },
                    ]
                },
                {
                    menuId: 62, primaryTitle: lan.menuAuditTrail, hasSecondary: false, shouldExpand: false, permission: [33],
                    secondaryContent: [
                        //{ menuId: 63, secondaryTitle: lan.menuPayInOut, iconTitle: "IO", permission: 33 },
                    ]
                },
                {
                    menuId: 22, primaryTitle: lan.menuGeneralSetup, hasSecondary: true, shouldExpand: false, permission: [17, 99, 40],
                    secondaryContent: [
                        { menuId: 28, secondaryTitle: lan.menuCompany, iconTitle: "CS", permission: 40 },
                        { menuId: 29, secondaryTitle: lan.menuSystem, iconTitle: "SS", permission: 17 },
                        { menuId: 32, secondaryTitle: lan.menuPrinter, iconTitle: "PS", permission: 17 },
                        { menuId: 33, secondaryTitle: lan.menuReason, iconTitle: "RP", permission: 17 },
                        { menuId: 41, secondaryTitle: lan.menuAttendSetup, iconTitle: "AS", permission: 17 },
                        { menuId: 43, secondaryTitle: lan.menuOutletSetup, iconTitle: "ST", permission: 17 },
                        { menuId: 64, secondaryTitle: lan.menuReserveSetting, iconTitle: "RS", permission: 17 },
                        { menuId: 79, secondaryTitle: "Restaurant Setup", iconTitle: "RS", permission: 17 },
                        { menuId: 90, secondaryTitle: "Table Setup", iconTitle: "SR", permission: 17 },
                        { menuId: 91, secondaryTitle: "Commission Setup", iconTitle: "SR", permission: 17 },
                        { menuId: 34, secondaryTitle: lan.menuDevice, iconTitle: "TD", permission: 17 },
                        { menuId: 36, secondaryTitle: lan.menuDatabase, iconTitle: "DB", permission: 99 },
                        { menuId: 140, secondaryTitle: "Loyalty Setting", iconTitle: "CS", permission: 17 },

                    ]
                }]
        });
    }
    onLangaugeChange = (state) => {
        this.setState({
            language: state.language,
            lanIndex: state.lanIndex
        });
        this.updateDrawer(state.lanIndex);
    }

    shouldExpand = (drawer) => {
        if (this.state.menuId === drawer.menuId) {
            if (!drawer.hasSecondary) {
                return true;
            } else {
                return drawer.shouldExpand;
            }
        } else if (drawer.hasSecondary) {
            for (let i = 0; i < drawer.secondaryContent.length; i++) {
                if (drawer.secondaryContent[i].menuId === this.state.menuId) {
                    return true;
                }
            }
        } else {
            return false;
        }
    }

    handleClick = (menuId) => () => {
        let drawer = this.state.drawer;
        for (let i = 0; i < drawer.length; i++) {
            if (menuId == drawer[i].menuId) {
                if (drawer[i].hasSecondary) {
                    if (menuId != this.state.menuId) {
                        drawer[i].shouldExpand = true;
                    } else {
                        drawer[i].shouldExpand = !drawer[i].shouldExpand;
                    }
                }
            }
        }
        this.setState({ drawer: drawer });
        menuAction.menuId(menuId);
        //go to certain page
        switch (menuId) {
            case 0:
                this.props.history.push('/fnb/dashboard');
                break;
            case 2:
                this.props.history.push('/fnb/hourlySales');
                break;
            case 27:
                this.props.history.push('/fnb/dailySales');
                break;
            case 3:
                this.props.history.push('/fnb/monthlySales');
                break;
            case 4:
                this.props.history.push('/fnb/yearlySales');
                break;
            case 5:
                this.props.history.push('/fnb/itemSales');
                break;
            case 7:
                this.props.history.push('/fnb/addProduct');
                break;
            case 8:
                this.props.history.push('/fnb/stockIn');
                break;
            case 9:
                this.props.history.push('/fnb/supplier');
                break;
            case 10:
                this.props.history.push('/fnb/receive');
                break;
            case 13:
                this.props.history.push('/fnb/stockTake');
                break;
            case 14:
                this.props.history.push('/fnb/stockOut');
                break;
            case 11:
                this.props.history.push('/fnb/products');
                break;
            case 25:
                this.props.history.push('/fnb/addPromotion');
                break;
            case 26:
                this.props.history.push('/fnb/promotionList');
                break;
            case 16:
                this.props.history.push('/fnb/addTable');
                break;
            /*
                case 18:
                this.props.history.push('/fnb/table');
                break;
            */
            case 20:
                this.props.history.push('/fnb/addEmployee');
                break;
            case 21:
                this.props.history.push('/fnb/employee');
                break;
            case 23:
                this.props.history.push('/fnb/accessControl');
                break;
            case 28:
                this.props.history.push('/fnb/companySetup');
                break;
            case 29:
                this.props.history.push('/fnb/systemSetup');
                break;
            //case 30:
            //this.props.history.push('/fnb/taxSetup');
            //break;
            //case 31:
            //this.props.history.push('/fnb/discountSetup');
            //break;
            case 32:
                this.props.history.push('/fnb/printerSetup');
                break;
            case 33:
                this.props.history.push('/fnb/reasonPreset');
                break;
            case 34:
                this.props.history.push('/fnb/deviceConnection');
                break;
            case 35:
                this.props.history.push('/fnb/transaction');
                break;
            case 36:
                this.props.history.push('/fnb/database');
                break;
            case 37:
                this.props.history.push('/fnb/dailyItemSales');
                break;
            case 38:
                this.props.history.push('/fnb/stockAdj');
                break;
            case 39:
                this.props.history.push('/fnb/productQtyReset');
                break;
            case 40:
                this.props.history.push('/fnb/shift');
                break;
            case 41:
                this.props.history.push('/fnb/attendSetup');
                break;
            case 42:
                this.props.history.push('/fnb/attend');
                break;
            case 43:
                this.props.history.push('/fnb/outlet');
                break;
            case 44:
                this.props.history.push('/fnb/outletSales');
                break;
            case 45:
                this.props.history.push('/fnb/tagSales');
                break;
            case 46:
                this.props.history.push('/fnb/stockTransfer');
                break;
            case 47:
                this.props.history.push('/fnb/lowStockItems');
                break;
            case 48:
                this.props.history.push('/fnb/purchaseOrder');
                break;
            case 49:
                this.props.history.push('/fnb/display');
                break;
            case 50:
                this.props.history.push('/fnb/modifier');
                break;
            case 51:
                this.props.history.push('/fnb/performanceOverall');
                break;
            case 53:
                this.props.history.push('/fnb/selfOrder');
                break;
            case 54:
                this.props.history.push('/fnb/selfOrderQr');
                break;
            case 55:
                this.props.history.push('/fnb/order');
                break;
            case 56:
                this.props.history.push('/fnb/employee/staffMeal');
                break;
            case 58:
                this.props.history.push('/fnb/staffMeal');
                break;
            case 59:
                this.props.history.push('/fnb/staffMealAdd');
                break;
            case 60:
                this.props.history.push('/fnb/customerSales');
                break;
            case 61:
                this.props.history.push('/fnb/ingredient');
                break;
            case 62:
                this.props.history.push('/fnb/auditTrail');
                break;
            case 64:
                this.props.history.push('/fnb/reserveSetting');
                break;
            case 65:
                this.props.history.push('/fnb/reportSetting');
                break;
            case 66:
                this.props.history.push('/fnb/unit');
                break;
            case 67:
                this.props.history.push('/fnb/pricebook');
                break;
            case 68:
                this.props.history.push('/fnb/stockBalance');
                break;
            case 70:
                this.props.history.push('/fnb/customer');
                break;
            case 71:
                this.props.history.push('/fnb/customergroup');
                break;
            case 72:
                this.props.history.push('/fnb/reserve');
                break;
            case 73:
                this.props.history.push('/fnb/serialNumber');
                break;
            case 74:
                this.props.history.push('/fnb/dailyOverallSales');
                break;
            case 75:
                this.props.history.push('/fnb/mummyReturn');
                break;
            case 76:
                this.props.history.push('/fnb/itemSalesSummary');
                break;
            case 77:
                this.props.history.push('/fnb/performance/daily');
                break;
            case 78:
                this.props.history.push('/fnb/performance/singer');
                break;
            case 79:
                this.props.history.push('/fnb/restaurantSetup');
                break;
            case 80:
                this.props.history.push('/fnb/category');
                break;
            case 81:
                this.props.history.push('/fnb/cogs');
                break;
            case 82:
                this.props.history.push('/fnb/employeeCom');
                break;
            case 83:
                this.props.history.push('/fnb/SalesSystemSummary');
                break;
            case 84:
                this.props.history.push('/fnb/singerShift');
                break;
            case 85:
                this.props.history.push('/fnb/singerOverall');
                break;
            case 86:
                this.props.history.push('/fnb/ReservationReport');
                break;
            case 87:
                this.props.history.push('/fnb/singer/detail');
                break;
            case 88:
                this.props.history.push('/fnb/holdReturn');
                break;
            case 89:
                this.props.history.push('/fnb/addSinger');
                break;
            case 90:
                this.props.history.push('/fnb/tableSetup');
                break;
            case 91:
                this.props.history.push('/fnb/commSetup');
                break;

            case 140:
                this.props.history.push('/fnb/customerSetting');
                break;
            case 137:
                this.props.history.push('/fnb/customer');
                break;
            case 138:
                this.props.history.push('/fnb/customergroup');
                break;
            case 139:
                this.props.history.push('/fnb/customerReward');
                break;
            case 142:
                this.props.history.push('/fnb/stockInOut');
                break;
        }

        //if (window.innerWidth < 600) {
        if (menuId != 1 && menuId != 6 && menuId != 12 && menuId != 16 && menuId != 24 && menuId != 19 && menuId != 22 &&
            menuId != 57 && menuId != 52 && menuId != 69 && menuId != 72 && menuId != 136) {
            menuAction.open(false);
        }
        //}
    }
    renderIcon = (menuId) => {
        switch (menuId) {
            case 0:
                return <DashboardIcon />;
            case 1:
                return <BarchartIcon />;
            case 12:
                return <LocalGroceryIcon />;
            case 6:
                return <RestaurantMenuIcon />;
            case 24:
                return <CakeIcon />
            case 16:
                return (
                    <SvgIcon>
                        <g className="cls-3" style={{ transform: "translate(2px, 6px)" }}>
                            <g className="cls-4">
                                <path d="M12.74,11h5.18a.75.75,0,0,0,0-1.5H6.57a.75.75,0,0,0,0,1.5h4.69v6.25H9.78v1.5h4.44v-1.5H12.74V11Z" transform="translate(-2.12 -5.5)" />
                                <path d="M8.82,13H4.27L3.61,6.18h0a.75.75,0,0,0-.74-.68.73.73,0,0,0-.74.75v.08h0l.74,7.4-.72,5H3.66L3.9,17H7.31v1.75H8.79V14.5h0a.73.73,0,0,0,.74-.75A.75.75,0,0,0,8.82,13ZM7.31,16H4l.22-1.5H7.31V16Z" transform="translate(-2.12 -5.5)" />
                                <path d="M21.88,6.33V6.25a.73.73,0,0,0-.74-.75.77.77,0,0,0-.74.68h0L19.73,13H15.19a.75.75,0,0,0,0,1.5h0v4.25h1.48V17H20.1l.25,1.75h1.51l-.72-5,.74-7.4ZM16.69,16V14.5h3.06L20,16Z" transform="translate(-2.12 -5.5)" />
                            </g>
                        </g>
                    </SvgIcon>
                );
            case 19:
                return <PeopleIcon />;
            case 22:
                return <DesktopIcon />;
            case 35:
                return <TransIcon />;
            case 40:
                return <ShiftIcon />;
            case 52:
                return (
                    <SvgIcon>
                        <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 21 21">
                            <rect x="2.79" y="2.79" width="5.48" height="5.48" stroke="white" strokeWidth="1px" fill="none" />
                            <rect x="12.74" y="2.79" width="5.47" height="5.47" stroke="white" strokeWidth="1px" fill="none" />
                            <rect x="2.79" y="12.96" width="5.43" height="5.43" stroke="white" strokeWidth="1px" fill="none" />
                            <rect x="12.74" y="12.91" width="5.48" height="5.48" stroke="white" strokeWidth="1px" fill="none" />
                            <line x1="2.57" y1="10.62" x2="18.43" y2="10.62" stroke="white" strokeWidth="1px" fill="none" />
                            <line x1="10.5" y1="2.78" x2="10.5" y2="18.4" stroke="white" strokeWidth="1px" fill="none" />
                            <rect x="4.38" y="4.39" width="2.28" height="2.28" stroke="white" strokeWidth="1px" fill="none" />
                            <rect x="14.34" y="4.39" width="2.28" height="2.28" stroke="white" strokeWidth="1px" fill="none" />
                            <rect x="14.34" y="14.53" width="2.28" height="2.28" stroke="white" strokeWidth="1px" fill="none" />
                            <rect x="4.34" y="14.57" width="2.28" height="2.28" stroke="white" strokeWidth="1px" fill="none" />
                            <polyline points="2.78 0.5 0.5 0.5 0.5 2.78" stroke="white" strokeWidth="1px" fill="none" />
                            <polyline points="18.22 0.5 20.5 0.5 20.5 2.78" stroke="white" strokeWidth="1px" fill="none" />
                            <polyline points="18.22 20.73 20.5 20.73 20.5 18.45" stroke="white" strokeWidth="1px" fill="none" />
                            <polyline points="2.78 20.73 0.5 20.73 0.5 18.45" stroke="white" strokeWidth="1px" fill="none" />
                        </svg>
                    </SvgIcon>
                );
            case 55:
                return (
                    <SvgIcon>
                        <svg viewBox="0 0 19.34 24.5">
                            <path d="M-578.3,264.78a1.4,1.4,0,0,1,.06-1.75,1.25,1.25,0,0,1,.9-.38h0a1.26,1.26,0,0,1,.9.38l0,0,.92,1.07v-5.18a1.29,1.29,0,0,1,1.27-1.31,1.29,1.29,0,0,1,1.27,1.31v1.44a1.17,1.17,0,0,1,.41-.07,1.27,1.27,0,0,1,1.13.7,1.3,1.3,0,0,1,.38-.12v-14a1.2,1.2,0,0,0-1.2-1.2h-14.15a1.2,1.2,0,0,0-1.2,1.2v17.46a1.2,1.2,0,0,0,1.2,1.2h8.5Zm-2.24-14.5h5c.21,0,.39.34.39.75s-.17.75-.39.75h-5c-.21,0-.39-.34-.39-.75S-580.75,250.27-580.54,250.27Zm0,4.56h2.91c.22,0,.39.34.39.75s-.17.75-.39.75h-2.91c-.21,0-.39-.34-.39-.75S-580.76,254.84-580.54,254.84Zm-3.34,6.8a1.45,1.45,0,0,1-1.45-1.45,1.45,1.45,0,0,1,1.45-1.45,1.45,1.45,0,0,1,1.45,1.45A1.45,1.45,0,0,1-583.88,261.63Zm0-4.59a1.45,1.45,0,0,1-1.45-1.45,1.45,1.45,0,0,1,1.45-1.45,1.45,1.45,0,0,1,1.45,1.45A1.45,1.45,0,0,1-583.88,257Zm0-4.59a1.45,1.45,0,0,1-1.45-1.45,1.45,1.45,0,0,1,1.45-1.45,1.45,1.45,0,0,1,1.45,1.45A1.45,1.45,0,0,1-583.88,252.45Zm5.06,8.44h-1.91c-.14,0-.26-.34-.26-.75s.12-.75.26-.75h1.91c.14,0,.26.34.26.75S-578.68,260.89-578.82,260.89Z" transform="translate(587.57 -245.66)" />
                            <path d="M-568.72,266a20.4,20.4,0,0,1-.86,3.71h-4.85c-.18-.38-.51-1.08-.67-1.37-.25-.44-.58-1-.94-1.5-.47-.72-1-1.54-1.47-2.41-.13-.22-.24-.53-.08-.69a.41.41,0,0,1,.29-.12.44.44,0,0,1,.29.12l1.66,1.94a.43.43,0,0,0,.48.12.44.44,0,0,0,.28-.41V259a.42.42,0,0,1,.41-.43.42.42,0,0,1,.41.43v4.1a.44.44,0,0,0,.43.44.44.44,0,0,0,.43-.44v-1.43a.42.42,0,0,1,.41-.43.42.42,0,0,1,.41.43v2a.44.44,0,0,0,.43.44.44.44,0,0,0,.43-.44v-1.43a.42.42,0,0,1,.41-.43.42.42,0,0,1,.41.43v2a.44.44,0,0,0,.43.44.44.44,0,0,0,.43-.44V263a.42.42,0,0,1,.41-.43.42.42,0,0,1,.41.43Z" transform="translate(587.57 -245.66)" />
                        </svg>
                    </SvgIcon>
                )
            case 57:
                return <StaffMealIcon />
            case 62:
                return <AuditIcon />
            case 69:
                return <MemberIcon />
            case 72:
                return <ReserveIcon />
            case 136:
                return <MemberIcon />
        }
    }
    renderSecondary = (drawer, dwr) => {
        const { classes, open, hover, user } = this.props;
        if (!this.shouldExpand(dwr) && !open && !hover) return;

        return drawer.map((dwr, index) => {
            if (user.permission.indexOf(dwr.permission) > -1) {
                let listItem = this.state.menuId == dwr.menuId ? classes.active : classes.inactive;
                return (
                    <ListItem className={listItem} button onClick={this.handleClick(dwr.menuId)} key={index}>
                        <Typography className={classes.listIconTxt} align="center">
                            {dwr.iconTitle}
                        </Typography>
                        <ListItemText className={classNames(classes.listItemTxt, (!open && !hover) && classes.listItemClose)}
                            disableTypography
                            primary={
                                <Typography variant="body2" className={classes.listIcon}>
                                    {dwr.secondaryTitle}
                                </Typography>
                            }
                        />
                    </ListItem>
                );
            }
        });
    }
    renderDrawer = () => {
        const { classes, open, hover, user } = this.props;

        return this.state.drawer
            .filter((dwr) => {
                for (let i = 0; i < dwr.permission.length; i++) {
                    if (user.permission.indexOf(dwr.permission[i]) > -1) {
                        return true;
                    }
                }

                return false;
            })
            .map((dwr, index) => {
                //display expand or collapse
                let expand = (this.shouldExpand(dwr) ?
                    <ExpandLess className={classNames(classes.listItemTxt, (!open && !hover) && classes.listItemClose)} />
                    :
                    <ExpandMore className={classNames(classes.listItemTxt, (!open && !hover) && classes.listItemClose)} />);
                //render secondary list
                let secondaryList;
                //decide if render Secondary list
                if (dwr.hasSecondary) {
                    secondaryList = (
                        <Collapse in={this.shouldExpand(dwr)} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {this.renderSecondary(dwr.secondaryContent, dwr)}
                            </List>
                        </Collapse>
                    );
                }
                let listItem = this.state.menuId == dwr.menuId ? classes.active : classes.inactive;

                return (
                    <div key={index}>
                        <ListItem className={listItem} button onClick={this.handleClick(dwr.menuId)}>
                            <ListItemIcon className={classes.listIcon}>
                                {this.renderIcon(dwr.menuId)}
                            </ListItemIcon>
                            <ListItemText className={classNames(classes.listItemTxt, (!open && !hover) && classes.listItemClose)}
                                disableTypography
                                primary={
                                    <Typography type="body2" style={{ color: 'white' }}>
                                        {dwr.primaryTitle}
                                    </Typography>
                                } />
                            {dwr.hasSecondary ? expand : ""}
                        </ListItem>
                        {secondaryList}
                    </div>
                );
            });
    }
    render() {

        return (
            <div>
                {this.renderDrawer()}
            </div>
        );
    }
}

DrawerContent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DrawerContent);
