'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DrawerContent from './drawerComponents/drawerContent';
import menuAction from '../actions/menuAction';
import menuStore from '../stores/menuStore';

const drawerWidth = 300;

const styles = theme => ({

    drawerPaper: {
        whiteSpace: 'nowrap',
        width: drawerWidth,
        backgroundColor: "#424242",
        borderRight: 'none',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        transform: `translateZ(0px)`,
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
        [theme.breakpoints.down('sm')]: {
            width: 0
        },
        transform: `translateZ(0px)`,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    listIcon: {
        color: 'white'
    },
    divider: {
        backgroundColor: "#e3e3e3",
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
    },
    list: {
        //overflowX: 'hidden',
        //overflowY: 'auto',
        //height: `calc(100vh - 75px)`,
    }

});

class MenuDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: menuStore.getState().open,
            hover: menuStore.getState().hover,
        };
    }
    componentDidMount() {
        menuStore.listen(this.onChange);
    }
    componentWillUnmount() {
        menuStore.unlisten(this.onChange);
    }
    onChange = (state) => {
        this.setState({
            open: state.open,
            hover: state.hover
        });
    };

    handleDrawerClose = () => {
        menuAction.open(false);
    };
    handleMouseEnter = () => {
        if (window.innerWidth > 600) {
            menuAction.hover(true);
        }
    }
    handleMouseOut = () => {
        if (window.innerWidth > 600) {
            menuAction.hover(false);
        }
    }
    render() {
        const { classes, history, user } = this.props;
        const { open, hover } = this.state;
        return (
            <Drawer
                variant="permanent"
                classes={{
                    paper: classNames(classes.drawerPaper, (!open && !hover) && classes.drawerPaperClose),
                }}
                open={open || hover}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseOut}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={this.handleDrawerClose} className={classes.listIcon}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider className={classes.divider} />
                <List className={classes.list}>
                    <DrawerContent open={open} hover={hover} history={history} user={user} />
                </List>
            </Drawer>

        );
    }
}

MenuDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuDrawer);