'use strict';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import menuAction from '../actions/menuAction';
import menuStore from '../stores/menuStore';
import languageStore from '../stores/languageStore';

class SnackBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            snackbarShow: menuStore.getState().snackbarShow,
            snackbarMessage: menuStore.getState().snackbarMessage,
            lanIndex: languageStore.getState().lanIndex,
        };
    }
    componentDidMount() {
        menuStore.listen(this.onChange);
        languageStore.listen(this.onLanguageChange);
    }
    componentWillUnmount() {
        menuStore.unlisten(this.onChange);
        languageStore.unlisten(this.onLanguageChange);
    }
    onChange = (state) => {
        this.setState({
            snackbarShow: state.snackbarShow,
            snackbarMessage: state.snackbarMessage,
        });
    }
    onLanguageChange = (state) => {
        this.setState({
            lanIndex: state.lanIndex
        });
    }
    handleClose = () => {
        menuAction.snackbarShow(false);
    }
    render() {
        const { snackbarShow, snackbarMessage, lanIndex } = this.state;
        return (

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                ContentProps={{
                    variant: `body1`,
                }}
                open={snackbarShow}
                autoHideDuration={6000}
                onClose={this.handleClose}
                message={snackbarMessage[lanIndex]}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={this.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        );
    }
}

export default SnackBar;