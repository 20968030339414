import alt from '../alt';

class menuAction {
    constructor() {
        this.generateActions(
            'open',
            'hover',
            'menuId',
            'showDialog',
            'warningMsg',
            'snackbarShow',
            'snackbarMessage',
        );
    }
}

export default alt.createActions(menuAction);