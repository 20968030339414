import alt from '../alt';
import languageAction from '../actions/languageAction';

class languageStore {
    constructor() {
        this.bindActions(languageAction);
        this.languageSelection = ["EN", "CH", "BM"];
        this.lanIndex = 0;
        this.language = [
            {
                menuDashBoard: "DashBoard",
                menuTransaction: "Transactions",
                menuOrder: "Orders",
                menuSalesReport: "Sales Reports",
                menuSalesCustomer: "Sales Report - Customer",
                menuSalesReportHourly: "Hourly Sales Report",
                menuSalesReportDaily: "Daily Sales Report",
                menuSalesReportMonthly: "Monthly Sales Report",
                menuSalesReportYearly: "Yearly Sales Report",
                menuSalesReportItem: "Monthly Items Report",
                menuSalesReportItemDaily: "Daily Items Report",
                menuSalesReportOutlet: "Outlet Sales Report",
                menuSalesReportTag: "Sales Report By Tag",
                menuIngredient: "Raw Material Report",
                menuProduct: "Products",
                menuAddProduct: "Add Product",
                menuProductList: "Product List",
                menuProductReset: "Daily Product Quantity Reset",
                menuStockManagement: "Stock Management",
                menuStockAdj: "Stock Adjustment",
                menuStockIn: "Stock In",
                menuStockTake: "Stock Take",
                menuStockOut: "Stock Out",
                menuStockTransfer: "Stock Transfer",
                menuStockPurchase: "Purchase Order",
                menuReceive: "Purchase Invoice",
                menuSupplier: "Suppliers",
                menuPromotion: "Promotions",
                menuPromotionAdd: "Add Promotion",
                menuPromotionList: "Promotion List",
                menuTable: "Table Management",
                menuTableAdd: "Add Table",
                menuTableList: "Table List",
                menuEmployee: "Employee Management",
                menuEmployeeAdd: "Add Employee",
                menuEmployees: "Employees",
                menuEmployeeAccess: "Employee Access Control",
                menuEmployeePerformance: "Employee Performance",
                menuGeneralSetup: "General Setup",
                menuCompany: "Company Setup",
                menuSystem: "System Setup",
                menuPrinter: "Printer Setup",
                menuReason: "Reason Preset",
                menuDevice: "Device Connection",
                menuDatabase: "Database",
                menuAttendance: "Employee Attendance",
                menuAttendSetup: "Attendance Setup",
                menuShift: "Shift Reports",
                menuOutletSetup: "Outlet Setup",
                menuCustomerDisplay: "Customer Display",
                menuProductModifier: "Modifier Setup",
                menuSelfOrder: "Self Ordering",
                menuSelfOrderSetup: "Self Ordering Setup",
                menuSelfOrderQr: "QR Code Generation",
                navWelcome: "Welcome",
                dashTitle: "DashBoard",
                dashNetSale: "Net Sales",
                dashRevenue: "Revenue",
                dashLastUpdate: "Last Update",
                dashPrevDay: "Last",
                dashPrevMonth: "Prev. Month",
                dashAccumulate: "Accumulative Daily Revenue",
                dashComPrevMonth: "compare to previous month",
                dashComPrevDay: "compare to previous day",
                dashComPrevYear: "compare to previous year",
                dashIncrease: "increase",
                dashDecrease: "decrease",
                dashTop: "Top",
                dashForMonth: "for the month of",
                dashCatBySale: "Categories By Sales",
                dashItemBySale: "Items By Sales",
                dashNetSaleDaily: "Daily Net Sales",
                dashNetSaleMonthly: "Monthly Net Sales",
                dashRevenueDaily: "Daily Revenue",
                dashRevenueMonthly: "Monthly Revenue",
                dashInMonthSale: "in this month sales",
                dashNetSaleHourly: "Hourly Net Sales",
                dashNetSaleYearly: "Yearly Net Sales",
                dashRevenueHourly: "Hourly Revenue",
                dashRevenueYearly: "Yearly Revenue",
                dashOutlet: "Outlets Summary",
                generalEndDate: "End Date",
                generalStartDate: "Start Date",
                generalSelectDate: "Select Date",
                generalStatus: "Status",
                generalSelectYear: "Select Year",
                generalSelectMonth: "Select Month",
                generalLegend: "Legend",
                generalDone: "Done",
                generalAction: "Action",
                generalCancel: "Cancel",
                generalAdd: "Add",
                generalUpload: "Upload",
                generalYes: "Yes",
                generalNo: "No",
                generalBack: "Back",
                generalNext: "Next",
                generalSubmit: "Submit",
                generalEdit: "Edit",
                generalOk: "Ok",
                generalAreUSure: "Are you Sure?",
                generalRemove: "Delete",
                generalComplete: "Complete",
                generalRetrieve: "Retrieve",
                generalUndo: "Undo",
                generalSave: "Save",
                generalSaveAndComplete: "Save and Complete",
                generalCreate: "Create",
                generalUpdate: "Update",
                generalTelephone: "Telephone",
                generalEmail: "Email",
                generalAddress: "Address",
                generalCity: "City",
                generalState: "State",
                generalPostal: "Postal",
                generalDate: "Date",
                generalName: "Name",
                generalNameWarning: "Name is required",
                generalHandPhoneWarning: "HandPhone is required",
                generalHandPhone: "HandPhone",
                generalRoleWarning: "Access level is required",
                generalRole: "Access Level",
                generalPosition: "Position",
                generalAddressWarning: "Address is required",
                generalCityWarning: "City is required",
                generalStateWarning: "State is required",
                generalPostalWarning: "Postal is required",
                generalTelephoneWarning: "Telephone is required",
                generalEmailWarning: "Email is required",
                generalError: "Error",
                generalTo: "To",
                generalImport: "Import",
                generalExport: "Export",
                generalChooseFile: "Choose File",
                generalHour: "Hour",
                generalHours: "Hours",
                generalMinute: "Minute",
                generalMinutes: "Minutes",
                generalSearch: "Search",
                generalSearchPlaceHolder: "Seach here",
                generalTag: "Tag",
                generalSeller: "Seller",
                generalBuyer: "Buyer",
                generalFieldWarning: "This Field is required",
                transTitle: "Transactions",
                transReceiptNumber: "Receipt Number",
                transDetailTitle: "Transaction Detail",
                transItem: "Description",
                transQty: "Qty",
                transPrice: "Price",
                transTotalAmt: "Total Amt",
                transAdditional: "Additional:",
                transPromotionDis: "Promotion Discount",
                transSpecialDis: "Special Discount",
                transPaidByCash: "Paid by Cash",
                transPaidByCC: "Paid by Credit Card",
                transChange: "Change",
                transSubtotal: "SubTotal",
                transDiscount: "Discount",
                transServiceCharge: "Service Charge",
                transRoundUp: "Rounding Adj.",
                transTotal: "Total",
                transBillNo: "Bill No",
                transCashier: "Cashier",
                transTable: "Table",
                transDineIn: "Dine In",
                transTakeaway: "Takeaway",
                transPromotion: "Promotion",
                transPax: "Pax",
                transPaymentTime: "Date",
                transVoid: "Void Bill",
                transRevMsg: "will be voided",
                transWaiter: "Waiter:",
                transItemReturn: "Return Item",
                transWriteOff: "Write Off",
                transRestock: "Restock",
                transClientName: "Add Client Name",
                transClient: "Client Name",
                transClientNameWarning: "Client Name is required",
                transExportReport: "Export Acct' Excel",
                transEditDate: "Edit Transaction Date",
                salesDailyTitle: "Daily Sales Report",
                salesHourlyTitle: "Hourly Sales Report",
                salesItemTitle: "Monthly Items Report",
                salesItemDailyTitle: "Daily Items Report",
                salesItemByTag: "Monthly Sales Report by Tag",
                salesMonthlyTitle: "Monthly Sales Report",
                salesYearlyTitle: "Yearly Sales Report",
                salesNetSalesDaily: "Daily Net Sales",
                salesTotalNetSales: "Total Net Sales",
                salesTotalNetSalesWoTax: "Total Net Sales excld.",
                salesComPrevDay: "compare to previous day",
                salesFilter: "Filter",
                salesSelectOption: "Please select Option",
                salesOption: "Variant",
                salesItem: "Items",
                salesFrom: "From",
                salesCategory: "Category",
                salesGroup: "Tags",
                salesYearNetSales: "Yearly Net Sales - Last 5 Years",
                salesComLastYear: "compare to Last Year",
                salesOptionWarning: "Please select Option Title",
                salesOutletTitle: "Outlet Sales Report",
                salesOutlet: "Outlets",
                salesTagWarning: "Please select Tag",
                itemOutletDelete: "Remove item from",
                itemAddTitle: "Add Product",
                itemCatEmpty: "Name cannot be emptied",
                itemGrpEmpty: "Group Name cannot be emptied",
                itemCatAdd: "New Category",
                itemGrpAdd: "New Group",
                itemCatName: "Category Name",
                itemGrpName: "Group Name",
                itemSelectEdit: "Select Item to Edit",
                itemCodeId: "Code Id",
                itemBarcodeId: "Barcode",
                itemProductName: "Product Name",
                itemProductNameShort: "Product Name Short",
                itemDescription: "Description",
                itemPictureRatio: "Picture ratio 4:3 (width: height)",
                itemSelectCat: "Select Category",
                itemSelectGrp: "Add Tag",
                itemQty: "Quantity",
                itemUnit: "Unit",
                itemLowStock: "Low Stock Alert",
                itemNonStock: "Non Stock",
                itemRawMaterial: "Is Raw Material",
                itemMaxDiscount: "Max Discount in %",
                itemUnitWarning: "Unit is Require",
                itemCost: "Cost",
                itemAveCost: "Ave. Cost",
                itemMinPrice: "Min Price",
                itemHasOption: "Has Variant:",
                itemCodeWarning: "Product codeId is required",
                itemNameWarning: "Product Name is required",
                itemNameShortWarning: "Product Name Short is required",
                itemNameShortDes: "Use to Display on the Receipt",
                itemOptNotReq: "Raw Material, Option Setup is not required",
                itemAddOpt: "Add Options",
                itemOptName: "Option Group",
                itemOptWarning: "Option Group is required",
                itemOptNameShort: "Option Group Short",
                itemMultiple: "Is Multiple:",
                itemOptList: "Option List:",
                itemFrmPro: "From Product",
                itemManual: "Manual",
                itemOptItem: "Option Item",
                itemOptItemWarning: "Option Item is required",
                itemOptItemShortWarning: "Option Item Short is required",
                itemOptItemShort: "Option Item Short",
                itemPrice: "Price",
                itemRetailPrice: "Retail Price",
                itemChargeable: "Is Chargeable:",
                itemNoOptWarning: "No option added",
                itemAddOptFrmPro: "Add Options from Product",
                itemFilter: "Filter Product",
                itemEvent: "Promotion Name",
                itemPriceList: "Price List",
                itemPriceListWarning: "Price is below Min. Price",
                itemDiscount: "Discount (in %)",
                itemDiscountWarning: "Discount is greater than Max. Discount",
                itemTaxWarning: "Tax cannot be emptied",
                itemFinalPrice: "Final Retail Price",
                itemPriceType: "Price Type:",
                itemFixCost: "Fixed Cost",
                itemVarCost: "Variable Cost",
                itemPriceNoReq: "Raw Material, Price Setup is not required",
                itemEdit: "Edit Product",
                itemSelectPro: "Select Product",
                itemWOTax: "w/o Tax",
                itemTax: "Tax",
                itemProDetail: "Product Detail",
                itemOnHand: "On Hand",
                itemRemove: "Remove Item",
                itemRevMsg: "will be removed permenantly.",
                itemCatWarning: "Category is Required",
                itemProList: "Product List",
                itemCodeDuplicate: "Code Id duplicate",
                itemImport: "Import Products",
                itemExport: "Export Products",
                itemExportBarcode: "Export Barcode",
                itemImportFromExcel: "Import Products by Excel file",
                itemPhrase1: "Download a",
                itemPhrase2: "Stock Template",
                itemPhrase3: "to see the example of the required format.",
                itemEmptyFile: "Empty File",
                itemPlusMinus: "Plus/Minus",
                itemAvailable: "Product available in",
                itemNewModifier: "New Modifier",
                itemModifier: "Modifier Setup",
                itemModifierSub: "Modifier is used for adding additional option/remark, e.g. less oil, less sugar",
                itemVariant: "Variant Setup",
                itemVariantSub: "Variant is used to categorize item, e.g. big size, medium size, small size",
                itemAddMod: "Add/Edit Modifier",
                itemSequence: "Sequence",
                itemCatExist: "Current Categories",
                itemCatRemove: "Delete Category",
                receiveConfirm: "Confirm Receive",
                receiveConfirmWarning: "Once you marked as 'Received', you are not able to edit anymore (except Remove)",
                receiveTitle: "Purchase Invoice",
                receiveNew: "New Purchase Invoice",
                receiveTotalAmt: "Total Amount",
                receiveTotalAmtWarning: "Total Amount is Incorrect",
                receiveTotalAmtWarning2: "Total Amount is required",
                receiveHolder: "Invoice",
                receiveBreakDown: "Breakdown",
                receiveSearch: "Search",
                receiveUnitCon: "Unit Conversion",
                receiveCostWarning: "Cost is required",
                receiveQtyWarning: "Qty is required",
                receiveUnitWarning: "Unit is required",
                receiveUnitConWarning: "Conversion is required",
                receiveItem: "Received Items",
                receiveRemove: "Remove Stock Receive",
                receiveSelectPur: "The selected receive",
                receiveInvoice: "Purchase Invoice No",
                receiveInvoiceWarning: "Purchase Invoice No is required",
                receiveSupplier: "Supplier",
                receiveSupplierWarning: "Supplier is required",
                receiveSupHolder: "Search By Supplier Id or Name",
                receiveDate: "Recevied Date",
                receiveUploadInv: "Upload Invoice",
                receiveUploadInvWarning: "Invoice Image is required",
                receiveDetail: "Received Detail",
                receivePOHelper: "Retrieve Items From Purchase Order",
                receiveOrderCost: "Ordered Cost",
                receiveOrderQty: "Ordered Qty",
                receiveOrderUnit: "Ordered Unit",
                stockAdjNew: "New Stock Adjustment",
                stockAdjTitle: "Stock Adjustment",
                stockAdjHolder: "Reference No",
                stockAdjName: "Adjusted By",
                stockAdjItem: "Adjusted Items",
                stockInTitle: "Stock In",
                stockOutTitle: "Stock Out",
                stockInNew: "New Stock In",
                stockTakeNew: "New Stock Take",
                stockOutNew: "New Stock Out",
                stockInEdit: "Edit Stock In",
                stockRefNo: "Reference No",
                stockRefNoWarning: "referenceNo is required",
                stockInDate: "Stock In Date",
                stockReason: "Reason",
                stockReasonWarning: "Reason cannot be emptied",
                stockInList: "Stock In List",
                stockInRemove: "Stock In Remove",
                stockAutoGenerate: "Auto Generate",
                stockOutEdit: "Edit Stock Out",
                stockOutDate: "Stock Out Date",
                stockOutList: "Stock Out List",
                stockOutRemove: "Stock Out Remove",
                stockCountItem: "Counted Items",
                stockUncountItem: "Uncounted Items",
                stockUncountAction: "Please select action for uncounted Items:",
                stockKeep: "Keep Expected Qty",
                stockReset: "Set Qty to 0",
                stockExpCountItem: "Export Counted Items",
                stockExpUncountItem: "Export Uncounted Items",
                stockExpItem: "Export StockTake Items",
                stockCounteQty: "Counted Qty",
                stockTakeTitle: "Stock Take",
                stockTakeCount: "Stock Take Count",
                stockTransferSourceOutlet: "Source Outlet",
                stockTransferDestOutlet: "Destination Outlet",
                stockTransferShip: "Ship",
                stockTransferSavenShip: "Save And Shipped",
                stockTransferReceive: "Received",
                stockTransferAveCost: "Ave. Cost",
                stockTransferOriQty: "Current On Hand",
                stockTransferConfirmShip: "Confirm ship",
                stockTransferItemToTransfer: "Items To Transfer",
                stockTransferOutletWarning: "Please select Outlet",
                stockTransferDeliveryDate: "Shipped Date",
                stockTransferShipWarning: "Once you marked as shipped, you cannot edit anymore.",
                stockTransferGeneral: "General Info",
                stockTransferCreateDate: "Create Date",
                stockTransferNew: "New Stock Transfer",
                stockTransferItemDuplicate: "Item Duplicate. Please combine into 1",
                stockLowItems: "Low Stock Items",
                stockPurchaseNew: "New Purchase Order",
                stockPurchaseConfirm: "Complete Purchase Order",
                stockPurchaseConfirmWarning: "Once you marked as completed, you cannot edit anymore except remove PO",
                stockPurchaseItemToPurchase: "Items",
                stockPurchaseOrderDate: "Order Date",
                stockPurchaseRemove: "Remove Purchase Order",
                stockPurchaseShouldInclude: "Include Low Stock Item",
                stockPurchaseDeliverTo: "Deliver To",
                supplierNew: "New Supplier",
                supplierEdit: "Edit Supplier",
                supplierId: "Supplier Id",
                supplierIdWarning: "Supplier Id is required",
                supplierName: "Supplier Name",
                supplierNameWarning: "Supplier Name is required",
                supplierRemove: "Supplier Remove",
                eventValid: "Promotion Duration:",
                eventValidWarning: "Promotion duration is required",
                eventAllDay: "All Day",
                eventSpecificDay: "At a Specific Time",
                eventStartTime: "Start Time",
                eventStartTimeWarning: "Start Time is required",
                eventEndTime: "End Time",
                eventEndTimeWarning: "End Time is required",
                eventPercentage: "Percentage",
                eventApplyTo: "Promotion Apply to:",
                eventPriority: "Priority:",
                eventPrioDes: "If multiple promotions overlapped at the same time, Promotion with higher priority number will supercede other Promotion",
                eventDaily: "Promotion On Every:",
                eventStartDate: "Promotion Start Date",
                eventEndDate: "Promotion End Date",
                eventEdit: "Edit Promotion",
                eventSelectExisting: "Select Existing Promotion",
                eventNameWarning: "Promotion Name is required",
                eventType: "Promotion Type:",
                eventRecurring: "Recurring Promotion (E.g. Happy Hour)",
                eventOneDay: "One Day Promotion (E.g. Anniversary)",
                eventRange: "Promotion On A Specific Date Range",
                eventTypeWarning: "Promotion Type is required",
                eventDate: "Promotion Date",
                eventRemove: "Remove Promotion",
                eventPromotionOn: "Promotion On",
                eventCondition: "Promotion Condition",
                eventConditionWarning: "Please select condition",
                eventFilterType: "Products filtered by",
                eventInclude: "Products Include:",
                eventExclude: "Products Exclude:",
                eventShouldExclude: "Exclude certain products",
                eventStartFrom: "Promotion Start From",
                eventMinAmount: "Spend Above:",
                eventAmount: "Amount",
                eventNew: "Add Promotion",
                eventList: "Promotion List",
                eventMaxQty: "Max Qty",
                eventMinQty: "Min Qty",
                eventDiscountType: "Discount Type",
                eventDiscountTypeWarning: "Discount Type is Required",
                eventFixedPrice: "Retail Price(Before Tax)",
                eventBundle: "Bundle Items",
                eventEffect: "Discount Applied to:",
                eventFollowingItem: "Following Items",
                eventSimple: "Basic Promotion",
                eventAdvance: "Advance Promotion",
                eventQtyWarning: "Qauntity is required",
                eventItems: "Items",
                tableNew: "Table Management",
                tableFloor: "Floor",
                tableOption: "Table Option",
                tableAddFloor: "Add Floor",
                tableEditFloor: "Edit Floor",
                tableFloorName: "Floor Name",
                tableFloorNameWarning: "Floor Name is reuqired",
                tableEdit: "Add/Edit Table",
                tableLabel: "Table Label",
                tableSelectFloor: "Select Floor",
                tableSelectFloorWarning: "Please select Floor",
                tableLabelWarning: "Table Label is required",
                tableLabelDuplicate: "Table Label duplicate",
                tableSelected: "The selected table",
                tableRemove: "Remove Table",
                tablePrefix: "Prefix",
                tableStartNo: "Start No",
                tableStartNoWarning: "Start No is required",
                tableEndNo: "End No",
                tableEndNoWarning: "End No is required",
                tableName: "Table",
                tableOutletFloorWarning: "Please select outlet and floor first",
                employeeSelect: "Select User",
                employeePosPermission: "POS Permission",
                employeePosHolder: "Used for POS",
                employeeWebPermission: "BackEnd Permission",
                employeeWebHolder: "Used for Website",
                employeePosPwdWarning: "Please type Password if allow user to access POS",
                employeePwdWarning: "Please type Password if allow user to access Backend",
                employeePwdWarning2: "Password must be >= 8 characters and at least One Capital Letter",
                employeePosPwdWarning2: "Please type password again",
                employeeEdit: "Edit User",
                employeePwd: "Password",
                employeePosPwd: "POS Password",
                employeePosAccess: "Used for POS Access",
                employeeTitle: "Employee",
                employeeNew: "New Employee",
                employeeAccessTitle: "Employee Access Control",
                systemResName: "Restaurant Name",
                systemResNameWarning: "Restaurant Name is required",
                systemRegNo: "Reg. No",
                systemRegNoWarning: "Reg. No is required",
                systemStreet: "Street/Building Name",
                systemStreetWarning: "Street/Building Name is required",
                systemReqSup: "Require Supervisor Approval",
                systemDiscounts: "Discounts",
                systemDisOverall: "Used for Product discount and Overall discount",
                systemDisDes: "Require Approval if Discount is greather than Product's Max Discount",
                systemDisExclude: "Exclude Options From Special Discount",
                systemServiceCharge: "Service Charge (in %)",
                systemServiceTaxable: "Is Service Charge Taxable?",
                systemServiceTax: "Service Tax (in %)",
                systemPayment: "Payment Methods",
                systemCash: "Cash",
                systemCredit: "Credit Card",
                systemPrinter: "Add/Edit Printer",
                systemPrinters: "Printers",
                systemPrinterName: "Printer Title",
                systemPrinterNameWarning: "Printer Title is required",
                systemPrinterIP: "Printer IP Address",
                systemPrinterIPWarning: "Printer IP Address is required",
                systemPrinterType: "Printer Type:",
                systemPrinterCashier: "Cashier",
                systemPrinterBev: "Kitchen/Beverage",
                systemPrinterPro: "Product to be printed",
                systemPrinterProCat: "Product Category",
                systemPrinterNew: "Add New Printer",
                systemPrinterTest: "Test Print",
                systemPrinterGuestCheck: "Guest Check",
                systemPrinterBig: "Big",
                systemPrinterSmall: "Small",
                systemPrinterWhole: "Print All Products in One Paper",
                systemPrinterSingle: "Seperate Products One By One",
                systemPrinterBoth: "Print All and Separate One By One",
                systemPrinterReceipt: "Print Option",
                systemPrinterSize: "Print Size",
                systemPrinterType2: "Paper Type",
                systemPrinterDevices: "Devices",
                systemReasonAdd: "Add Reason",
                systemReason: "Reason",
                systemReasonCancel: "Cancel Order Reason",
                systemReasonVoid: "Void Bill Reason",
                systemReasonRefund: "Refund Reason",
                systemRoundUpTo: "Rounding Adj. to",
                systemCurrencySymbol: "Currency Symbol",
                systemCurrency: "Currency",
                systemNewBank: "New Banknote / Coin",
                systemBankList: "List of Banknotes and Coins",
                systemTaxReg: "Tax Reg. No",
                systemTaxName: "Tax Name",
                systemTaxNameWarning: "Tax Name is required",
                systemTaxApplyToAll: "Apply Tax to All Products",
                systemTax: "Tax (in %)",
                systemGeneral: "General Setup",
                systemSetup: "System Setup",
                systemTaxSetup: "Tax Setup",
                systemPaymentSetup: "Service & Payment Setup",
                systemDiscountSetup: "Discount Setup",
                systemOther: "Other",
                systemDeviceConnection: "Device Connection",
                systemBackup: "Backup Database",
                systemBackupSecondary: "Backup all data into personal storage",
                systemDelete: "Reset Transaction Data",
                systemDeleteSecondary: "Warning: All transactions, stockIn, stockOut, stockTake, and shift data will be removed permanently",
                systemDeleteAll: "Reset All Data",
                systemDeleteAllSecondary: "Warning: All data will be removed permanently",
                systemTypeText: "Please type",
                systemTextMatchWarning: "Text doesn't Match",
                systemPrinterGuestCheck: "Guest Check",
                systemOtherPaymentMethod: "Other Payment Method",
                systemPaymentMethod: "Payment Method",
                systemPaymentType: "Payment Type",
                systemOtherPaymentMethodWarning: "Please select payment method",
                shOpeningTime: "Opening Time",
                shClosingTime: "Closing Time",
                shOpeningAmount: "Initial Drawer Amount",
                shExpectedAmount: "Expected Drawer Amount",
                shClosingAmount: "Actual Drawer Amount",
                shShiftEdit: "Edit Shift",
                shOpeningDate: "Opening Date",
                shClosingDate: "Closing Date",
                shOpening: "Opening Shift",
                shClosing: "Closing Shift",
                shTransaction: "Transactions",
                shShort: "Short",
                shTotalPaymentReceive: "Total Payment Received",
                shCash: "Cash",
                shCredit: "Credit",
                shVisa: "Visa",
                shMaster: "Master",
                shAmex: "Amex",
                shTotalGrossSale: "Total Gross Sales",
                shRoundAdj: "Rounding Adj",
                shSalesSummary: "Sales Summary",
                shSalesSummaryByTag: "Sales Summary By Tag",
                shNoVoid: "No of Void Bill",
                shNoRefund: "No of Refund",
                shVoidAmount: "Total Void Bill Amount",
                shRefundAmount: "Total Refund Amount",
                shAt: "at",
                shBy: "by",
                shServiceCharge: "Service Charges",
                shDineIn: "Dine In",
                attendAllowDevice: "Device Allow to Time In/Out",
                attendRemove: "Remove Employee Attendance",
                attendEdit: "Edit Employee Attendance",
                attendTimeIn: "Time In",
                attendTimeOut: "Time Out",
                attendTotalDuration: "Total Working Hours",
                outletName: "Outlet Name",
                outletEdit: "Add/Edit Outlet",
                outletSetup: "Outlet Setup",
                outletShouldClone: "Clone from other outlet",
                outletClone: "Outlet",
                outletCloneWarning: "Please select outlet to clone",
                outletNew: "Add New Outlet",
                outletAll: "All Outlets",
                outletWarning: "Please select outlet",
                displayAdd: "New Customer Display",
                displayNew: "Add/Edit Customer Display",
                displayTitle: "Title",
                displayTitleWarning: "Title is required",
                displayPicture: "Images",

                systemPrinterSticker: "Sticker",
                systemPrinterHeight: "Sticker Height",
                systemPrinterWidth: "Sticker Width",
                systemPrinterGap: "Sticker Gap",
                generalPreview: "Print Preview",
                generalSubtotal: "SubTotal",
                generalDiscount: "Discount",
                generalTotalAmount: "Total Amount",
                generalRequireWarning: "This field is required",
                generalPrint: "Print",
                generalSwitchToDO: "Switch To DO",
                generalSwitchToST: "Switch To ST",
                menuDeliveryOrder: "Delivery Order",
                menuPurchaseOrder: "Purchase Order",

                selfOrderBgImg: "Background Image",
                selfOrderRecommendation: "Food Recommendation",
                selfOrderRefreshWarning: "All current Qr Code will be removed and re-generate. Are you sure?",
                generalGenerate: "Generate",
                generalRefresh: "Refresh",
                selfOrderQrCode: "Qr Code",
                selfOrderStatic: "Static",
                selfOrderDynamic: "Dynamic",

                orderDetailTitle: "Order Detail",
                orderClientName: "Client Name",
                orderTime: "Order Time",

                transLast4Digit: "Last 4 Digit",
                generalAddFromModifier: "Add From Modifier",
                generalStaffBenefit: "Staff Benefit",
                menuStaffMealSetup: "Staff Meal Setup",
                menuStaffMeal: "Staff Meal",
                menuStaffMealList: "Staff Meal List",
                menuStaffMealAdd: "Add Staff Meal",
                menuSingerShift: "Singer Shift",
                menuSingerOveral: "Singer Overal",
                menuSingerDetail: "Singer Detail",
                staffMealMaxAmount: "Has Maximum Amount (monthly)",
                staffMealApplyToAll: "Discount On All Products",
                eventExcludeOption: "Should Promotion Apply to Add On Option",
                systemPrinterFooter: "Footer",
                systemPrinterConType: "Connection Type",
                salesCustomer: "Customers",
                salesAveCustomerPerDay: "Customers/Day",
                salesAveSpendingPerPerson: "$/customer",
                salesTableTurnOver: "Table Turnover Rate",
                salesTableDistribute: "Table Occupied Distribution",
                itemRecipe: "Recipe",
                itemAddRecipe: "Add Recipe",
                systemPrinterNoReceipt: "No of Copy",
                shPayIn: "Cash In",
                shPayOut: "Cash Out",
                menuPayInOut: "Cash In / Out",
                menuAuditTrail: "Audit Trail",
                dashNetSaleWeekly: "Weekly Net Sales",
                systemServiceExclude: "Exclude Service Charge from Takeaway",
                menuTableSetting: "Table Settings",
                menuSaleReportSetting: "Report Settings",
                systemTableSettingAdd: "Add Table Duration",
                generalDuration: "Duration",
                generalColor: "Color",
                systemServiceChargeMode: "Is service charge apply to All",
                itemAllowDis: "Allow Discount",
                itemAllowFOC: "Allow FOC",
                itemIsSerial: "Has Serial Number",
                itemAllowEnt: "Allow Entertainment",
                itemUnitAdd: "Units",
                itemUnitValue: "Value",
                itemEntAmt: "Entertainment",
                itemCommAmt: "Commission",
                menuPriceBook: "Price Books",
                itemUnitRemove: "Remove Unit",
                pricebookRemove: "Remove Price Book",
                pricebookAdd: "Add Price Book",
                pricebookAddEdit: "Add/Edit Price Book",
                pricebookPromotion: "For Promotion",
                pricebookApplyForAll: "Apply to All",
                pricebookCustomer: "Customers",
                shiftCode: "Shift No",
                menuStockBalance: "Stock Balance",
                eventPricebook: "Promotion base on Price Book",
                menuCustomer: "Customers Management",
                menuCustomerList: "Customer List",
                menuCustomerGroup: "Customer Groups",
                menuCustomerGroupAdd: "Add Customer Group",
                customerGroupRemove: "Remove Customer Group",
                customerAdd: "Add Customer",
                customerRemove: "Remove Customer",
                customerEdit: "Edit Customer",
                customerName: "Customer Name",
                menuReserveSetting: "Reserve Setting",
                stockSerialNo: "Serial No",
                menuReservation: "Reservation",
                reserveAdd: "Add Reservation",
                reserveEdit: "Edit Reservation",
                reserveShift: "Shift",
                reserveEmployee: "Staff",
                reserveDate: "Reserved Date",
                menuSerialNumber: "Serial Number",
                generalPrintBarcode: "Print Barcode",
                generalCopyAndPaste: "Copy And Paste",
                customerLastPurchase: "Last Purchase Date",
                customerTotalSpent: "Total Spent",
                customerTotalTrans: "Total Transaction",
                employeeLevyOrCPF: "Levy Or CPF",
                employeeLevyAmount: "Levy/CPF Amount",
                entMaxBringFwd: "Max. Bring Forward (in mth)",
                entMinCommission: "Min. Commision for Levy Exemption",
                entRedeemPts: "Free ENT Pts",
                systemENTSetup: "ENT Setup",
                itemMMReward: "Min. Qty To Have Free ENT",
                itemMMRedeem: "Reward Pts To Redeem",
                entTotalCommission: "Total Commission",
                entTotalENT: "Total ENT",
                entClaim: "ENT Claimed",
                entMCredit: "MCredit",
                reserveCancel: "Cancel Reservation",
                menuSalesReportDailyOverall: "Daily Overall Sales Report",
                salesTotal: "SALES TOTAL",
                menuMummyReturn: "M Credit Refund",
                mummyRemove: "Cancel M Credit Refund",
                entMCreditReturn: "M Credit Refund",
                systemBottleInSetup: "Bottle In Setup",
                systemShiftSetup: "Shift Setup",
                systemShiftSequence: "Please follow sequence accordingly",
                systemPrinterModel: "Printer Model",
                generalPrintAll: "Print All",
                itemEntQty: "Actual Qty Count",
                itemLevyQty: "Qty Earned - Levy Subsidy",
                employeeLevySubsidizeAmount: "Levy Subsidize Amt",
                employeeMinBottle: "Min. Bottle",
                //to be added
                menuSalesReportItemCheck: "Item Sales Summary",
                employeeMultiply: "Multiply",
                menuEmployeePerformanceDaily: "Employee Daily Performance",
                menuSingerPerformance: "Singer Performance",

                itemCategoryAdd: "Add Category",
                itemIsSub: "Is Sub Category",
                itemMainCategory: "Main Category",

                menuSalesSystem: "System Sales Summary",
                salesMetrics: "Sales Metrics",
                netSales: "Net Sales",
                revenue: "Revenue",
                taxCollected: "Tax Collected",
                totalRev: "Total Revenue",
                nonRevenueSVC: "NON Revenue SVC",
                totalCollection: "Total Collections",
                itemDiscounts: "Item Discounts",
                subDiscount: "Subtotal Discounts",
                totalDiscount: "Total Discounts",
                costOfGoodSold: "Cost Of Goods Sold",
                otherIncome: "Other Income",
                adjustment: "Adjustment",
                generalReturn: "Returns",
                generalVoid: "Voids",
                generalCreditTotal: "Credit Total",
                generalRoundingTotal: "Rounding Total",
                mgrVoid: "Mgr Voids",
                errorCorrect: "Error Corrects",
                cancels: "Cancels",
                giftCard: "Gift Cards",
                giftCardSales: "Gift Card Sales",
                tips: "Tips",
                chargeTips: "Charge Tips",
                cashTips: "Cash Tips Decl",
                indirectTips: "Indirect Tips",
                totalTips: "Total Tips",
                tipsPaid: "Tips Paid",
                tipsDue: "TipsDue",
                generalChecks: "Checks",
                carriedOver: "Carried Over",
                checkBegun: "Checks Begun",
                checkPaid: "Checks Paid",
                checkTransOuts: "Transferred Out",
                checkTransIn: "Transferred In",
                generalOutstanding: "Outstanding",
                generalDeposits: "Deposits",
                cashDue: "Cash Due",
                deposit: "Deposit",
                overshort: "Over / Short",
                cashCollected: "Cash Collected",
                paidIn: "Paid In",
                paidOut: "Paid Out",
                tipsPaid: "Tips Paid",
                businessDate: "Business Dates",
                generalLocation: "Locations",
                revenueCenters: "Revenue Centers",
                systemResNameShort: "Company Name (Short)",
                filterList: "Filter List",
                itemExtraInfo: "Extra Info",
                menuCustomerReward: "Customer Loyalty Rewards",
                customerRewardAdd: "New Reward",
                customerRewardEdit: "Edit Reward",
                itemRewardPts: "Reward Pts",
                RechargeableCard: "Rechargeable Card",
                rewardBaseOnBoth: "Selling Price or Individual Pts",
                salesTotalOtherSales: "Top Up Amount",
                generalDob: "DOB",
                generalGender: "Gender",
                itemTopUpAmt: "Top Up Amt",
                customerRedeemed: "Redeem Amt",
                customerAvailable: "Available Amt",
                groupRewardPoints: "Additional Group Rewards",
                hasBirthdayScheme: "Birthday Rewards",
                birthdayMonth: "Birthday Month",
                birthday: "On That Day",
                hasExpiry: "Reward Points has Expiry date",
                pointValidDuration: "Reward Points valid for (days)",
                customerPointForfeit: "Forfeit",
                systemLoyaltyPoints: "Loyalty Points",
                totalReward: "Total Earned Points",
                totalRedeem: "Total Redeemed Points",
                totalForfeit: "Total Forfeits Point",
                totalAvailable: "Total Available Points",
                printCustomerDetail: "Print Customer Detail",
                printBreakdownRewardPts: "Print Rewards Detail",
                customerPromo: "Membership Promotions",
                itemHasExpiry: "Has expiry date",
                expiryDate: "Expiry Date",
                noOfDay: "No Of Days",
                itemBreakdown: "Recent Bought Item",
                cardNo: "Card No",
                customerTotalTrans: "Total Transaction",
                customerTotalSpent: "Total Spending",
                customerLastPurchase: "Last Purchase",
                customerPointEarned: "Point Earned",
                customerPointRedeemed: "Point Redeem",
                customerPointAvailable: "Point Available",
                menuCustomerSetting: "Customer Setting",
                redeemConversion: "Conversion (1pts = ? $)",
                rewardConversion: "Conversion (1$ = ? pts)",
                rewardType: "Reward Pts gain base on:",
                rewardBaseOnTotal: "Bill Amounts",
                rewardBaseOnIndividual: "Individual Items",
                mutiplyRewardPoints: "Additional Reward Points",
                menuCustomerReward: "Customer Loyalty Rewards",
                customerRewardAdd: "New Reward",
                customerRewardEdit: "Edit Reward",
                RechargeableCard: "For Member Top Up",
                newMemberReward: "New Member Reward",
                maxRedemption: "Max. Redemption",
                qlubPrefix: "Qlub Prefix",
                qlubSeq: "Qlub Sequence",
            },
            {
                menuDashBoard: "摘要",
                menuTransaction: "交易",
                menuOrder: "订单",
                menuSalesCustomer: "销售报告-顾客",
                menuSalesReport: "销售报告",
                menuSalesReportHourly: "销售报告-小时",
                menuSalesReportDaily: "销售报告-每日",
                menuSalesReportMonthly: "销售报告-每月",
                menuSalesReportYearly: "销售报告-每年",
                menuSalesReportItem: "销售报告-物品(每月)",
                menuSalesReportItemDaily: "销售报告-物品(每日)",
                menuSalesReportOutlet: "分行销售报告",
                menuSalesReportTag: "销售报告-标签",
                menuIngredient: "原材料摘要",
                menuProduct: "物品",
                menuAddProduct: "添加物品",
                menuProductList: "物品列表",
                menuProductReset: "每日物品数量重置",
                menuStockManagement: "货物管理",
                menuStockAdj: "货品数量更改",
                menuStockIn: "货品存入",
                menuStockTake: "货品点算",
                menuStockOut: "货品取出",
                menuStockTransfer: "货品转移",
                menuStockPurchase: "货品预定",
                menuReceive: "购买收据",
                menuSupplier: "供应商",
                menuPromotion: "促销",
                menuPromotionAdd: "添加促销",
                menuPromotionList: "促销列表",
                menuTable: "桌子管理",
                menuTableAdd: "添加桌子",
                menuTableList: "桌子列表",
                menuEmployee: "员工管理",
                menuEmployeeAdd: "添加员工",
                menuEmployees: "员工列表",
                menuEmployeeAccess: "员工权限",
                menuEmployeePerformance: "员工表现",
                menuDevice: "设备连接",
                menuGeneralSetup: "一般设置",
                menuCompany: "公司设置",
                menuSystem: "系统设置",
                menuPrinter: "打印机设置",
                menuReason: "预置原因",
                menuDatabase: "数据库",
                menuShift: "轮班报告",
                menuAttendance: "员工上下班",
                menuAttendSetup: "员工上下班设置",
                menuOutletSetup: "分行设置",
                menuCustomerDisplay: "客户显示",
                menuProductModifier: "选项设置",
                menuSelfOrder: "自动点餐",
                menuSelfOrderSetup: "自动点餐设置",
                menuSelfOrderQr: "QR Code 生成",
                menuSingerShift: "歌手班次",
                menuSingerOveral: "歌手总体",
                menuSingerDetail: "歌手详情",
                navWelcome: "欢迎",
                dashTitle: "摘要",
                dashNetSale: "净总销售额",
                dashRevenue: "总收入",
                dashLastUpdate: "最新更新",
                dashPrevDay: "上个",
                dashPrevMonth: "上个月",
                dashAccumulate: "每日累计收入",
                dashComPrevMonth: ",与上个月的比较",
                dashComPrevDay: ",与前一天的比较",
                dashComPrevYear: ",与前一年的比较",
                dashIncrease: "上升",
                dashDecrease: "下降",
                dashTop: "",
                dashForMonth: " - ",
                dashCatBySale: "大受欢迎类别",
                dashItemBySale: "大受欢迎食物",
                dashNetSaleDaily: "每日净销售额",
                dashNetSaleMonthly: "月度净销售额",
                dashRevenueDaily: "每日收入",
                dashRevenueMonthly: "月度收入",
                dashInMonthSale: ",与上个月的比较",
                dashNetSaleHourly: "每小时净销售额",
                dashNetSaleYearly: "年度净销售额",
                dashRevenueHourly: "每小时收入",
                dashRevenueYearly: "年度收入",
                dashOutlet: "分行销售",
                generalEndDate: "到",
                generalStartDate: "从",
                generalSelectDate: "选择日期",
                generalStatus: "状况",
                generalSelectYear: "选择年份",
                generalSelectMonth: "选择月份",
                generalLegend: "说明",
                generalDone: "完成",
                generalAction: " ",
                generalCancel: "取消",
                generalAdd: "添加",
                generalUpload: "上传",
                generalYes: "是",
                generalNo: "否",
                generalBack: "返回",
                generalNext: "下一个",
                generalSubmit: "提交",
                generalEdit: "更改",
                generalOk: "Ok",
                generalAreUSure: "你确定吗?",
                generalRemove: "删除",
                generalComplete: "完成",
                generalRetrieve: "取回",
                generalUndo: "复原",
                generalSave: "储存",
                generalSaveAndComplete: "完成点算",
                generalCreate: "创立",
                generalUpdate: "更新",
                generalTelephone: "电话",
                generalEmail: "邮件地址",
                generalAddress: "地址",
                generalCity: "城市",
                generalState: "州",
                generalPostal: "邮区",
                generalDate: "日期",
                generalName: "名字",
                generalNameWarning: "请填写名字",
                generalHandPhoneWarning: "请填写手机号码",
                generalHandPhone: "手机号码",
                generalRoleWarning: "请填写员工权限",
                generalRole: "员工权限",
                generalPosition: "职位",
                generalAddressWarning: "地址必须填写",
                generalCityWarning: "城市必须填写",
                generalStateWarning: "州属必须填写",
                generalPostalWarning: "邮区必须填写",
                generalTelephoneWarning: "电话必须填写",
                generalEmailWarning: "邮件地址必须填写",
                generalError: "系统错误",
                generalTo: "至",
                generalImport: "导入",
                generalExport: "导出",
                generalChooseFile: "选择文件",
                generalHour: "小时",
                generalHours: "小时",
                generalMinute: "分钟",
                generalMinutes: "分钟",
                generalSearch: "搜索",
                generalSearchPlaceHolder: "点击这里搜索",
                generalTag: "标签",
                generalSeller: "卖家",
                generalBuyer: "买家",
                generalFieldWarning: "请填写",
                transTitle: "交易记录",
                transReceiptNumber: "收据序号",
                transDetailTitle: "交易详情",
                transItem: "物品",
                transQty: "数量",
                transPrice: "价格",
                transTotalAmt: "总数",
                transAdditional: "额外:",
                transPromotionDis: "促销折扣",
                transSpecialDis: "特别折扣",
                transPaidByCash: "现金付款",
                transPaidByCC: "行用卡付款",
                transChange: "零钱",
                transSubtotal: "小计",
                transDiscount: "折扣",
                transServiceCharge: "服务费",
                transRoundUp: "近似",
                transTotal: "总数",
                transBillNo: "账单序号",
                transCashier: "收银员",
                transTable: "桌子",
                transDineIn: "内用",
                transTakeaway: "外带",
                transPromotion: "促销",
                transPax: "人数",
                transPaymentTime: "付款时间",
                transVoid: "账单作废",
                transRevMsg: "将会作废",
                transWaiter: "服务员:",
                transItemReturn: "货物退回",
                transWriteOff: "删除",
                transRestock: "退回仓库",
                transClientName: "添加顾客名字",
                transClient: "顾客名字",
                transClientNameWarning: "顾客名字必须填写",
                transExportReport: "导出会计excel资料",
                transEditDate: "更改交易日期",
                salesDailyTitle: "销售报告-每日",
                salesHourlyTitle: "销售报告-小时",
                salesItemTitle: "销售报告-物品(每月)",
                salesItemDailyTitle: "销售报告-物品(每日)",
                salesItemByTag: "销售报告-标签(每月)",
                salesMonthlyTitle: "销售报告-每月",
                salesYearlyTitle: "销售报告-每年",
                salesNetSalesDaily: "每日总净销售额",
                salesTotalNetSales: "总净销售额",
                salesTotalNetSalesWoTax: "总净销售额不包括",
                salesComPrevDay: ",与前一天的比较",
                salesFilter: "筛选",
                salesSelectOption: "请选择",
                salesOption: "选项",
                salesItem: "大受欢迎食物",
                salesFrom: "从",
                salesCategory: "类别",
                salesGroup: "标签",
                salesYearNetSales: "过去五年总销售额",
                salesComLastYear: ",与上一年的比较",
                salesOptionWarning: "请选择",
                salesOutletTitle: "分行销售报告",
                salesOutlet: "分行",
                salesTagWarning: "请选择标签",
                itemOutletDelete: "物品删除于",
                itemAddTitle: "添加物品",
                itemCatEmpty: "不能空白",
                itemGrpEmpty: "组别不能空白",
                itemCatAdd: "新类别",
                itemGrpAdd: "新组别",
                itemCatName: "类别",
                itemGrpName: "组别",
                itemSelectEdit: "选择更改物品",
                itemCodeId: "物品序号",
                itemBarcodeId: "条码",
                itemProductName: "物品名称",
                itemProductNameShort: "物品名称简写",
                itemDescription: "描述",
                itemPictureRatio: "照片比例 4:3 (宽: 高)",
                itemSelectCat: "选择类别",
                itemSelectGrp: "添加标签",
                itemQty: "数量",
                itemUnit: "单位",
                itemLowStock: "数量过低警惕",
                itemNonStock: "不可估算货品",
                itemRawMaterial: "原料",
                itemMaxDiscount: "最高折扣 (%)",
                itemUnitWarning: "单位必须填写",
                itemCost: "成本",
                itemAveCost: "平均成本",
                itemMinPrice: "最低售价",
                itemHasOption: "选项:",
                itemCodeWarning: "物品序号必须填写",
                itemNameWarning: "物品名称必须填写",
                itemNameShortWarning: "物品名称简写必须填写",
                itemNameShortDes: "用于显示在收据上",
                itemOptNotReq: "改物品属于原料, 无需选项建立",
                itemAddOpt: "添加选项",
                itemOptName: "组名称",
                itemOptWarning: "组名称必须填写",
                itemOptNameShort: "组名称简写",
                itemMultiple: "多选项:",
                itemOptList: "选项列表:",
                itemFrmPro: "从现有物品添加",
                itemManual: "手工添加",
                itemOptItem: "选项物品名称",
                itemOptItemWarning: "选项物品名称必须填写",
                itemOptItemShortWarning: "选项物品名称简写必须填写",
                itemOptItemShort: "选项物品名称简写",
                itemPrice: "额外价格",
                itemRetailPrice: "零售价格",
                itemChargeable: "额外收费:",
                itemNoOptWarning: "无选项添加",
                itemAddOptFrmPro: "从现有物品添加",
                itemFilter: "筛选物品",
                itemEvent: "促销名称",
                itemPriceList: "价格列表",
                itemPriceListWarning: "售价低于最低售价",
                itemDiscount: "折扣 (%)",
                itemDiscountWarning: "折扣大于最高折扣",
                itemTaxWarning: "税不能空白",
                itemFinalPrice: "最终售价",
                itemPriceType: "售价类型:",
                itemFixCost: "定价",
                itemVarCost: "根据市价",
                itemPriceNoReq: "该物品属于原料, 无需价格建立",
                itemEdit: "更改物品",
                itemSelectPro: "选择物品",
                itemWOTax: "不包括税",
                itemTax: "税",
                itemProDetail: "货品介绍",
                itemOnHand: "存货量",
                itemRemove: "删除物品",
                itemRevMsg: "将会永久删除",
                itemCatWarning: "请选择类别",
                itemProList: "物品列表",
                itemCodeDuplicate: "Code Id 重复",
                itemImport: "导入物品",
                itemExport: "导出物品",
                itemExportBarcode: "导出条码",
                itemImportFromExcel: "通过Excel文件导入物品",
                itemPhrase1: "下载",
                itemPhrase2: "物品Excel文件",
                itemPhrase3: "来参考所需填写格式",
                itemEmptyFile: "请上传文件",
                itemPlusMinus: "加/减",
                itemAvailable: "此物品在",
                itemNewModifier: "添加选项",
                itemModifier: "其他选项设置",
                itemModifierSub: "其他选项是用来添加一些选择/备注,例如: 少盐, 少糖, 加糖",
                itemVariant: "选项设置",
                itemVariantSub: "选项是用来区分物品分类,例如: 大份, 中份, 小份",
                itemAddMod: "添加/更改 其他选项",
                itemSequence: "顺序",
                itemCatExist: "现有类别",
                itemCatRemove: "删除类别",
                receiveConfirm: "确定完成收货",
                receiveConfirmWarning: "一旦状态改为‘收货’,你将无法更改该(除了删除)",
                receiveTitle: "补货",
                receiveNew: "添加货品补货",
                receiveTotalAmt: "总数",
                receiveTotalAmtWarning: "总数不符",
                receiveTotalAmtWarning2: "总数必须填写",
                receiveHolder: "收据号码",
                receiveBreakDown: "货品详系列表",
                receiveSearch: "搜索",
                receiveUnitCon: "单位换算",
                receiveCostWarning: "成本必须填写",
                receiveQtyWarning: "数量必须填写",
                receiveUnitWarning: "单位必须填写",
                receiveUnitConWarning: "单位换算必须填写",
                receiveItem: "补货物品",
                receiveRemove: "删除补货",
                receiveSelectPur: "已选补货",
                receiveInvoice: "收据号码",
                receiveInvoiceWarning: "收据号码必须填写",
                receiveSupplier: "供应商",
                receiveSupplierWarning: "供应商必须填写",
                receiveSupHolder: "搜索供应商号码或者名字",
                receiveDate: "补货日期",
                receiveUploadInv: "上传收据",
                receiveUploadInvWarning: "请上传收据",
                receiveDetail: "补货详情",
                receivePOHelper: "从预购订单获取物品",
                receiveOrderCost: "订购价格",
                receiveOrderQty: "订购数量",
                receiveOrderUnit: "订购单位",
                stockAdjNew: "调整货品数量",
                stockAdjTitle: "货品数量调整",
                stockAdjHolder: "参考序号",
                stockAdjName: "调整者",
                stockAdjItem: "调整物品",
                stockInTitle: "货品存入",
                stockOutTitle: "货品取出",
                stockInNew: "添加货品存入",
                stockTakeNew: "创建新货品点算",
                stockOutNew: "添加货品取出",
                stockInEdit: "更改货品存入",
                stockRefNo: "参考序号",
                stockRefNoWarning: "参考序号必须填写",
                stockInDate: "存入日期",
                stockReason: "原因",
                stockReasonWarning: "原因必须填写",
                stockInList: "货品存入记录",
                stockInRemove: "删除货品存入记录",
                stockAutoGenerate: "自动生成",
                stockOutEdit: "更改货品取出",
                stockOutDate: "取出日期",
                stockOutList: "货品取出",
                stockOutRemove: "删除货品取出记录",
                stockCountItem: "已点算物品",
                stockUncountItem: "未点算物品",
                stockUncountAction: "未点算物品:",
                stockKeep: "保留于其数量",
                stockReset: "全部改为零",
                stockExpCountItem: "下载已点算物品资料",
                stockExpUncountItem: "下载未点算物品资料",
                stockExpItem: "下载资料",
                stockCounteQty: "点算数量",
                stockTakeTitle: "物品点算",
                stockTakeCount: "点算物品",
                stockTransferSourceOutlet: "分行(从)",
                stockTransferDestOutlet: "分行(到)",
                stockTransferShip: "出货",
                stockTransferSavenShip: "储存 & 出货",
                stockTransferReceive: "货品收到",
                stockTransferAveCost: "平均成本价格",
                stockTransferOriQty: "现有数量",
                stockTransferItemToTransfer: "要转移的物品",
                stockTransferOutletWarning: "请选择分行",
                stockTransferDeliveryDate: "出货日期",
                stockTransferConfirmShip: "确定出货",
                stockTransferShipWarning: "一旦状态改为‘出货’,你将无法进行任何更改",
                stockTransferGeneral: "一般资料",
                stockTransferCreateDate: "创立时间",
                stockTransferNew: "新建货品转移",
                stockTransferItemDuplicate: "货品重复,请将同样货品结合",
                stockLowItems: "过低数量物品",
                stockPurchaseNew: "创立新订单",
                stockPurchaseConfirm: "完成订单",
                stockPurchaseConfirmWarning: "一旦状态改为‘完成’,你将无法进行任何更改(删除例外)",
                stockPurchaseItemToPurchase: "物品列表",
                stockPurchaseOrderDate: "订购时间",
                stockPurchaseRemove: "删除货品订购",
                stockPurchaseShouldInclude: "加入过低物品",
                stockPurchaseDeliverTo: "运送到",
                supplierNew: "创立新供应商",
                supplierEdit: "更改供应商",
                supplierId: "供应商号码",
                supplierIdWarning: "供应商号码必须填写",
                supplierName: "供应商名字",
                supplierNameWarning: "供应商名字必须填写",
                supplierRemove: "删除供应商",
                eventValid: "促销限于:",
                eventValidWarning: "此项必须填写",
                eventAllDay: "全天",
                eventSpecificDay: "特定时段",
                eventStartTime: "开始于",
                eventStartTimeWarning: "请选择",
                eventEndTime: "结束于",
                eventEndTimeWarning: "请选择",
                eventPercentage: "巴仙",
                eventApplyTo: "此折扣用于:",
                eventPriority: "优先:",
                eventPrioDes: "如果同一时间有其他促销，越大优先值将会取代其他促销",
                eventDaily: "此促销于:",
                eventStartDate: "促销开始于",
                eventEndDate: "促销结束于",
                eventEdit: "更改促销",
                eventSelectExisting: "选择现有促销",
                eventNameWarning: "促销名字必须填写",
                eventType: "促销种类:",
                eventRecurring: "经常性促销 (E.g. 欢乐时光)",
                eventOneDay: "一日促销 (E.g. 周年庆典)",
                eventRange: "促销发生于特定日期范围",
                eventTypeWarning: "请选择促销种类",
                eventDate: "促销日期",
                eventRemove: "删除促销",
                eventPromotionOn: "促销发生于",
                eventStartFrom: "促销开始于",
                eventNew: "创建新促销",
                eventList: "促销列表",
                eventCondition: "促销条件",
                eventConditionWarning: "请选择促销条件",
                eventFilterType: "物品筛选根据",
                eventInclude: "促销物品包括:",
                eventExclude: "促销物品不包括:",
                eventShouldExclude: "不包括指定物品",
                eventMinAmount: "消费超过:",
                eventAmount: "金额",
                eventMaxQty: "最大数量",
                eventMinQty: "最小数量",
                eventDiscountType: "促销类型",
                eventDiscountTypeWarning: "促销类型必须填写",
                eventFixedPrice: "零售价(税之前)",
                eventBundle: "包装物品",
                eventEffect: "促销用于:",
                eventFollowingItem: "接下来的物品",
                eventSimple: "一般促销",
                eventAdvance: "特别促销",
                eventQtyWarning: "请填写数量",
                eventItems: "物品",
                tableNew: "桌子管理",
                tableAddFloor: "添加楼层",
                tableEditFloor: "更改楼层",
                tableFloor: "楼",
                tableFloorName: "楼层名字",
                tableFloorNameWarning: "楼层名字必须填写",
                tableEdit: "添加/更改楼层",
                tableLabel: "桌子标签",
                tableSelectFloor: "选择楼层",
                tableSelectFloorWarning: "请选择楼层",
                tableLabelWarning: "桌子标签必须填写",
                tableLabelDuplicate: "桌子标签重复",
                tableSelected: "所选桌子",
                tableRemove: "删除桌子",
                tablePrefix: "桌子标签开头",
                tableStartNo: "桌子号码开始于",
                tableStartNoWarning: "请填写桌子开始号码",
                tableEndNo: "桌子号码结束于",
                tableEndNoWarning: "请填写桌子结束号码",
                tableOption: "桌子类型",
                tableName: "桌子",
                tableOutletFloorWarning: "请先选择分行还有楼层",
                employeeSelect: "选择用户",
                employeePosPermission: "POS 权限",
                employeePosHolder: "用于 POS",
                employeeWebPermission: "后台权限",
                employeeWebHolder: "用于后台",
                employeePosPwdWarning: "如果允许用户使用POS，请输入密码",
                employeePwdWarning: "如果允许用户使用后台，请输入密码",
                employeePwdWarning2: "密码必须大于或等于8个字符还有其中一个字符必须大写",
                employeePosPwdWarning2: "请从新输入密码",
                employeeEdit: "更改用户",
                employeePwd: "密码",
                employeePosPwd: "POS 密码",
                employeePosAccess: "用于POS",
                employeeTitle: "员工",
                employeeNew: "添加员工",
                employeeAccessTitle: "员工权限控制",
                systemResName: "餐馆名字",
                systemResNameWarning: "餐馆名字必须填写",
                systemRegNo: "商业注册号",
                systemRegNoWarning: "商业注册号必须填写",
                systemStreet: "路名/建筑",
                systemStreetWarning: "路名/建筑必须填写",
                systemReqSup: "需要上司允许",
                systemDiscounts: "折扣",
                systemDisOverall: "用于物品折扣或者最终折扣",
                systemDisDes: "需要上司允许如果折扣大过物品最高折扣",
                systemDisExclude: "折扣不包括选项",
                systemServiceCharge: "服务费 (%)",
                systemServiceTaxable: "服务费有被征税?",
                systemServiceTax: "税额 (%)",
                systemPayment: "付款方式",
                systemCash: "现金",
                systemCredit: "行用卡",
                systemPrinter: "添加/更改 打印机",
                systemPrinters: "打印机",
                systemPrinterName: "打印机名字",
                systemPrinterNameWarning: "打印机名字必须填写",
                systemPrinterIP: "打印机 IP 地址",
                systemPrinterIPWarning: "打印机 IP 地址必须填写",
                systemPrinterType: "打印类别:",
                systemPrinterCashier: "柜台",
                systemPrinterBev: "厨房/饮料",
                systemPrinterPro: "打印物品",
                systemPrinterProCat: "物品类别",
                systemPrinterNew: "添加新打印机",
                systemPrinterTest: "测试打印",
                systemPrinterGuestCheck: "顾客检查",
                systemPrinterBig: "大",
                systemPrinterSmall: "小",
                systemPrinterWhole: "打印全部物品在同一张单",
                systemPrinterSingle: "分开打印物品",
                systemPrinterBoth: "打印全部和分开打印物品",
                systemPrinterReceipt: "打印选项",
                systemPrinterSize: "字体大小",
                systemPrinterType2: "打印格式",
                systemPrinterDevices: "设备",
                systemOtherPaymentMethod: "其他付款方式",
                systemPaymentMethod: "付款方式",
                systemPaymentType: "付款类型",
                systemOtherPaymentMethodWarning: "请选择付款方式",
                systemReasonAdd: "添加原因",
                systemReason: "原因",
                systemReasonCancel: "取消订单原因",
                systemReasonVoid: "账单作废原因",
                systemReasonRefund: "退款原因",
                systemRoundUpTo: "近似于",
                systemCurrencySymbol: "货币名称",
                systemCurrency: "货币",
                systemNewBank: "新纸钞/钱币",
                systemBankList: "纸钞和钱币列表",
                systemTaxReg: "税务登记号码",
                systemTaxName: "税名称",
                systemTaxNameWarning: "税名称必须填写",
                systemTaxApplyToAll: "用于全部物品",
                systemTax: "税 (%)",
                systemGeneral: "一般设置",
                systemSetup: "系统设置",
                systemTaxSetup: "税收设置",
                systemPaymentSetup: "服务费&付款设置",
                systemDiscountSetup: "折扣设置",
                systemOther: "其他",
                systemDeviceConnection: "设备连接",
                systemBackup: "拷贝数据库资料",
                systemBackupSecondary: "将所有数据资料拷贝到个人资料",
                systemDelete: "重置交易数据",
                systemDeleteSecondary: "警告: 所有交易记录, 库存记录, 营业记录将会被永久删除",
                systemDeleteAll: "重置所有数据",
                systemDeleteAllSecondary: "警告: 所有数据将会被永久删除",
                systemTypeText: "请输入",
                systemTextMatchWarning: "文字输入不符",
                shOpeningTime: "开始时间",
                shClosingTime: "结束时间",
                shOpeningAmount: "开始收银机款额",
                shExpectedAmount: "预期收银机款额",
                shClosingAmount: "实际收银机款额",
                shShiftEdit: "更改每日结单",
                shOpeningDate: "开始日期",
                shClosingDate: "结束日期",
                shOpening: "营业开始",
                shClosing: "营业结束",
                shTransaction: "交易",
                shShort: "缺",
                shTotalPaymentReceive: "总收取款额",
                shCash: "现金",
                shCredit: "信用卡",
                shVisa: "Visa",
                shMaster: "Master",
                shAmex: "Amex",
                shTotalGrossSale: "总销售额",
                shRoundAdj: "近似",
                shSalesSummary: "销售摘要",
                shSalesSummaryByTag: "销售摘要根据标签",
                shNoVoid: "账单取消数量",
                shNoRefund: "退款数量",
                shVoidAmount: "账单取消总额",
                shRefundAmount: "退款总额",
                shAt: "于",
                shBy: "由",
                shServiceCharge: "服务费",
                shDineIn: "内用",
                attendAllowDevice: "设备允许员工上下班",
                attendRemove: "删除员工上下班记录",
                attendEdit: "更改员工上下班记录",
                attendTimeIn: "上班时间",
                attendTimeOut: "下班时间",
                attendTotalDuration: "总工作时间",
                outletName: "分行店名",
                outletEdit: "添加/更改 分行",
                outletSetup: "分行 设置",
                outletShouldClone: "复制其他分行",
                outletClone: "分行",
                outletCloneWarning: "请选择分行",
                outletNew: "添加新分行",
                outletAll: "全部分行",
                outletWarning: "请选择",
                displayAdd: "添加客户显示",
                displayNew: "添加/更改 客户显示",
                displayTitle: "标题",
                displayTitleWarning: "请填写",
                displayPicture: "图片",

                systemPrinterSticker: "标签",
                systemPrinterHeight: "标签 高",
                systemPrinterWidth: "标签 宽",
                systemPrinterGap: "标签 间隔",

                generalPreview: "打印预览",
                generalSubtotal: "小计",
                generalDiscount: "折扣",
                generalTotalAmount: "总额",
                generalRequireWarning: "此项目必须填写",
                generalPrint: "打印",
                generalSwitchToDO: "换成 DO",
                generalSwitchToST: "换成 ST",
                menuDeliveryOrder: "出货",
                menuPurchaseOrder: "购货",

                selfOrderBgImg: "背景图片",
                selfOrderRecommendation: "推荐食物",
                selfOrderRefreshWarning: "全部的Qr Code将会被删除然后从新生成，你确定？",
                generalGenerate: "生成",
                generalRefresh: "从新生成",
                selfOrderQrCode: "Qr Code",
                selfOrderStatic: "静态",
                selfOrderDynamic: "动态",

                orderDetailTitle: "下单资料",
                orderClientName: "顾客名字",
                orderTime: "下单时间",

                transLast4Digit: "最后四位数",
                generalAddFromModifier: "从其他选项添加",
                generalStaffBenefit: "员工福利",
                menuStaffMealSetup: "员工餐设置",
                menuStaffMeal: "员工餐",
                menuStaffMealList: "员工餐列表",
                menuStaffMealAdd: "添加员工餐",

                staffMealMaxAmount: "是否有最高限额 (每月)",
                staffMealApplyToAll: "全部物品都有则扣",
                eventExcludeOption: "此促销用于物品选项",
                systemPrinterFooter: "页脚",
                systemPrinterConType: "连接方式",
                salesCustomer: "顾客",
                salesAveCustomerPerDay: "顾客/天",
                salesAveSpendingPerPerson: "$/顾客",
                salesTableTurnOver: "换桌率",
                salesTableDistribute: "桌子分配情况",
                itemRecipe: "秘方",
                itemAddRecipe: "添加秘方",
                systemPrinterNoReceipt: "打印数量",
                shPayIn: "收进",
                shPayOut: "支出",
                menuPayInOut: "支出/收进",
                menuAuditTrail: "审计追踪",
                dashNetSaleWeekly: "净销售额-每周",
                systemServiceExclude: "外带不增收服务费",
                menuTableSetting: "桌子设置",
                menuSaleReportSetting: "报告设置",
                systemTableSettingAdd: "添加时段",
                generalDuration: "时间",
                generalColor: "颜色",
                systemServiceChargeMode: "此服务费用于全部物品",
                itemAllowDis: "允许折扣",
                itemAllowFOC: "允许免费赠送",
                itemIsSerial: "有Serial Number",
                itemAllowEnt: "允许当成娱乐费",

                itemUnitAdd: "单位",
                itemUnitValue: "值",
                itemEntAmt: "娱乐费",
                itemCommAmt: "佣金",
                menuPriceBook: "价格表",
                itemUnitRemove: "删除单位",
                pricebookRemove: "删除价格表",
                pricebookAdd: "添加新价格表",
                pricebookAddEdit: "添加/更改新价格表",
                pricebookPromotion: "用于促销",
                pricebookApplyForAll: "用于所有顾客",
                pricebookCustomer: "顾客",
                shiftCode: "班次编号",
                menuStockBalance: "库存",
                eventPricebook: "根据价格表做促销",
                menuCustomer: "客户管理",
                menuCustomerList: "客户列表",
                menuCustomerGroup: "客户类别",
                menuCustomerGroupAdd: "添加客户类别",
                customerGroupRemove: "删除客户类别",
                customerAdd: "添加新客户",
                customerRemove: "删除客户",
                customerEdit: "更改客户",
                customerName: "客户名字",
                menuReserveSetting: "预定设置",
                stockSerialNo: "序列号",
                menuReservation: "预定",
                reserveAdd: "添加预定",
                reserveEdit: "更改预定",
                reserveShift: "班次",
                reserveEmployee: "员工",
                reserveDate: "预定 日期",
                menuSerialNumber: "序列号",
                generalPrintBarcode: "打印条码",
                generalCopyAndPaste: "复制",
                customerLastPurchase: "最近消费",
                customerTotalSpent: "总消费",
                customerTotalTrans: "总消费次数",
                employeeLevyOrCPF: "Levy Or CPF",
                employeeLevyAmount: "Levy/CPF 额",
                entMaxBringFwd: "保留时间 (以月计算)",
                entMinCommission: "最低佣金用于豁免levy",
                entRedeemPts: "免费 ENT 分数",
                systemENTSetup: "ENT 设置",
                itemMMReward: "最低销售数量以享有免费ENT",
                itemMMRedeem: "所需分数来换取",
                entTotalCommission: "总佣金",
                entTotalENT: "总 ENT",
                entClaim: "以索取 ENT",
                entMCredit: "MCredit",
                reserveCancel: "取消预定",
                menuSalesReportDailyOverall: "每日总销售额",
                salesTotal: "SALES TOTAL",
                menuMummyReturn: "M Credit Refund",
                mummyRemove: "取消 M Credit Refund",
                entMCreditReturn: "M Credit Refund",
                systemBottleInSetup: "存酒设置",
                systemShiftSetup: "轮班设置",
                systemShiftSequence: "请根据顺序",
                systemPrinterModel: "打印机类型",
                itemEntQty: "实际获得数量",
                itemLevyQty: "获得数量-用于所得税",
                employeeLevySubsidizeAmount: "税津贴",
                employeeMinBottle: "最少数量",
                menuSalesReportItemCheck: "物品销售摘要",
                employeeMultiply: "津贴次数",
                menuEmployeePerformanceDaily: "员工每日表现",
                menuSingerPerformance: "歌手表现",
                itemCategoryAdd: "添加类别",
                itemIsSub: "是子类别",
                itemMainCategory: "主要类别",

                menuSalesSystem: "System Sales Summary",
                salesMetrics: "Sales Metrics",
                netSales: "Net Sales",
                revenue: "Revenue",
                taxCollected: "Tax Collected",
                totalRev: "Total Revenue",
                nonRevenueSVC: "NON Revenue SVC",
                totalCollection: "Total Collections",
                itemDiscounts: "Item Discounts",
                subDiscount: "Subtotal Discounts",
                totalDiscount: "Total Discounts",
                costOfGoodSold: "Cost Of Goods Sold",
                otherIncome: "Other Income",
                adjustment: "Adjustment",
                generalReturn: "Returns",
                generalVoid: "Voids",
                generalCreditTotal: "Credit Total",
                generalRoundingTotal: "Rounding Total",
                mgrVoid: "Mgr Voids",
                errorCorrect: "Error Corrects",
                cancels: "Cancels",
                giftCard: "Gift Cards",
                giftCardSales: "Gift Card Sales",
                tips: "Tips",
                chargeTips: "Charge Tips",
                cashTips: "Cash Tips Decl",
                indirectTips: "Indirect Tips",
                totalTips: "Total Tips",
                tipsPaid: "Tips Paid",
                tipsDue: "TipsDue",
                generalChecks: "Checks",
                carriedOver: "Carried Over",
                checkBegun: "Checks Begun",
                checkPaid: "Checks Paid",
                checkTransOuts: "Transferred Out",
                checkTransIn: "Transferred In",
                generalOutstanding: "Outstanding",
                generalDeposits: "Deposits",
                cashDue: "Cash Due",
                deposit: "Deposit",
                overshort: "Over / Short",
                cashCollected: "Cash Collected",
                paidIn: "Paid In",
                paidOut: "Paid Out",
                tipsPaid: "Tips Paid",
                businessDate: "Business Dates",
                generalLocation: "Locations",
                revenueCenters: "Revenue Centers",
                systemResNameShort: "Company Name (Short)",
                filterList: "Filter List",
                itemExtraInfo: "Extra Info",
                groupRewardPoints: "额外会员组别积分",
                hasBirthdayScheme: "会员生日积分",
                birthdayMonth: "当月生日",
                birthday: "当天生日",
                hasExpiry: "积分过期",
                pointValidDuration: "积分保留天数 (days)",
                customerPointForfeit: "过期",
                systemLoyaltyPoints: "会员积分",
                hasMaxDiscount: "有上限",
                maxDiscount: "最高折扣额",
                totalReward: "总积分",
                totalRedeem: "已使用积分",
                totalForfeit: "已报废积分",
                totalAvailable: "可使用积分",
                printCustomerDetail: "打印客户资料",
                printBreakdownRewardPts: "打印物料积分明细",
                customerPromo: "会员升级",
                expiryDate: "过期日期",
                itemHasExpiry: "是否会过期",
                noOfDay: "天数",
                itemBreakdown: "近期购买物料",
                cardNo: "卡号",
                customerTotalTrans: "Total Transaction",
                customerTotalSpent: "Total Spending",
                customerLastPurchase: "Last Purchase",
                customerPointEarned: "Point Earned",
                customerPointRedeemed: "Point Redeem",
                customerPointAvailable: "Point Available",
                menuCustomerSetting: "Customer Setting",
                redeemConversion: "Conversion (1pts = ? $)",
                rewardConversion: "Conversion (1$ = ? pts)",
                rewardType: "Reward Pts gain base on:",
                rewardBaseOnTotal: "Bill Amounts",
                rewardBaseOnIndividual: "Individual Items",
                mutiplyRewardPoints: "Additional Reward Points",
                menuCustomerReward: "Customer Loyalty Rewards",
                customerRewardAdd: "New Reward",
                customerRewardEdit: "Edit Reward",
                RechargeableCard: "For Member Top Up",
                newMemberReward: "New Member Reward",
                maxRedemption: "Max. Redemption",
                qlubPrefix: "Qlub Prefix",
                qlubSeq: "Qlub Sequence",
            },
            {
                menuDashBoard: "Dasbor",
                menuTransaction: "Transaksi",
                menuOrder: "Perintah",
                menuSalesCustomer: "Laporan Jualan Customer",
                menuSalesReport: "Laporan Jualan",
                menuSalesReportHourly: "Laporan Jualan Jam",
                menuSalesReportDaily: "Laporan Jualan Harian",
                menuSalesReportMonthly: "Laporan Jualan Bulan",
                menuSalesReportYearly: "Laporan Jualan Tahun",
                menuSalesReportItem: "Laporan Item Bulanan",
                menuSalesReportItemDaily: "Laporan Item Harian",
                menuSalesReportOutlet: "Laporan Jualan Outlet",
                menuSalesReportTag: "Laporan Jualan Mengikut Tag",
                menuIngredient: "Laporan Raw Produk",
                menuProduct: "Produk",
                menuAddProduct: "Tambah Produk",
                menuProductList: "Senarai Produk",
                menuProductReset: "Reset Semula Produk Harian",
                menuStockManagement: "Pengurusan Stok",
                menuStockAdj: "Pelarasan Stok",
                menuStockIn: "Masuk Stok",
                menuStockTake: "Angkat Stok",
                menuStockOut: "Keluar Stok",
                menuStockTransfer: "Pemindahan Stok",
                menuStockPurchase: "Pesanan Pembelian",
                menuReceive: "Pembelian Invois",
                menuSupplier: "Pembekal",
                menuPromotion: "Promosi",
                menuPromotionAdd: "Tambah Promosi",
                menuPromotionList: "Senarai Promosi",
                menuTable: "Meja Pengurusan",
                menuTableAdd: "Tambah Meja",
                menuTableList: "Senarai Meja",
                menuEmployee: "Pengurusan Pekerja",
                menuEmployeeAdd: "Tambah Pekerja",
                menuEmployees: "Pekerja",
                menuEmployeeAccess: "Kawalan Akses Pekerja",
                menuEmployeePerformance: "Pekerja Prestasi",
                menuGeneralSetup: "Persediaan Umum",
                menuCompany: "Persediaan Syarikat",
                menuSystem: "Persediaan Sistem",
                menuPrinter: "Persediaan Pencetak",
                menuReason: "Sebab Pre-set",
                menuDevice: "Sambungan Peranti",
                menuDatabase: "Pangkalan Data",
                menuAttendance: "Kehadiran Pekerja",
                menuAttendSetup: "Persediaan Kehadiran",
                menuShift: "Laporan Shift",
                menuOutletSetup: "Persediaan Outlet",
                menuCustomerDisplay: "Paparan Pelanggan",
                menuProductModifier: "Pilihan",
                menuSelfOrder: "Sendiri Perintah",
                menuSelfOrderSetup: "Sendiri Perintah Persediaan",
                menuSelfOrderQr: "QR Code Menjana",
                menuSingerShift: "Shift Penyanyi",
                menuSingerOveral: "Transaksi Penyanyi",
                menuSingerDetail: "Perincian Penyanyi",
                navWelcome: "Selamat Datang",
                dashTitle: "Dasbor",
                dashNetSale: "Jualan Bersih",
                dashRevenueSale: "Jualan Hasil",
                dashLastUpdate: "Kemaskini Terakhir",
                dashPrevDay: "Prev. Hari",
                dashPrevMonth: "Prev. Bulan",
                dashAccumulate: "Jualan Hasil Harian Akumulatif",
                dashComPrevMonth: "berbanding bulan sebelumnya",
                dashComPrevDay: "berbanding hari sebelum",
                dashComPrevYear: "berbanding tahun sebelum",
                dashIncrease: "Meningkat",
                dashDecrease: "Menurun",
                dashTop: "Atas",
                dashForMonth: "untuk bulan",
                dashCatBySale: "Kategori Mengikut Jualan",
                dashItemBySale: "Barangan Mengikut Jualan",
                dashNetSaleDaily: "Jualan Bersih Harian",
                dashNetSaleMonthly: "Jualan Bersih Bulanan",
                dashRevenueDaily: "Jualan Hasil Harian",
                dashRevenueMonthly: "Jualan Hasil Bulanan",
                dashInMonthSale: "jualan dalam bulan ini",
                dashNetSaleHourly: "Jualan Bersih Setiap Jam",
                dashNetSaleYearly: "Jualan Bersih Setiap Tahun",
                dashRevenueHourly: "Jualan Hasil Setiap Jam",
                dashRevenueYearly: "Jualan Hasil Setiap Tahun",
                dashOutlet: "Ringkasan Outlet",
                generalEndDate: "Tarikh Tamat",
                generalStartDate: "Tarikh Mula",
                generalSelectDate: "Tarikh Pilih",
                generalStatus: "Status",
                generalSelectYear: "Pilih Tahun",
                generalSelectMonth: "Pilih Bulan",
                generalLegend: "Legenda",
                generalDone: "Done",
                generalAction: "Tindakan",
                generalCancel: "Batalkan",
                generalAdd: "Tambah",
                generalUpload: "Upload",
                generalYes: "Yes",
                generalNo: "No",
                generalBack: "Balik",
                generalNext: "Seterusnya",
                generalSubmit: "Hantar",
                generalEdit: "Edit",
                generalOk: "Ok",
                generalAreUSure: "Adakah Anda Pasti?",
                generalRemove: "Padam",
                generalComplete: "Lengkap",
                generalRetrieve: "Pemulihan",
                generalUndo: "Undo",
                generalSave: "Simpan",
                generalSaveAndComplete: "Simpan dan Lengkap",
                generalCreate: "Cipta",
                generalUpdate: "Kemaskinikan",
                generalTelephone: "Telefon",
                generalEmail: "Email",
                generalAddress: "Alamat",
                generalCity: "Bandar",
                generalState: "Negeri",
                generalPostal: "Pos",
                generalDate: "Tarikh",
                generalName: "Nama",
                generalNameWarning: "Nama diperlukan",
                generalHandPhoneWarning: "Telefon Bimbit diperlukan",
                generalHandPhone: "Telefon Bimbit",
                generalRoleWarning: "kawalan akses diperlukan",
                generalRole: "kawalan akses",
                generalPosition: "Peranan",
                generalAddressWarning: "Alamat diperlukan",
                generalCityWarning: "Bandar diperlukan",
                generalStateWarning: "Negeri diperlukan",
                generalPostalWarning: "Pos diperlukan",
                generalTelephoneWarning: "Telefon diperlukan",
                generalEmailWarning: "Email diperlukan",
                generalError: "Kesilapan",
                generalTo: "Kepada",
                generalImport: "Import",
                generalExport: "Export",
                generalChooseFile: "Pilih File",
                generalHour: "Jam",
                generalHours: "Jams",
                generalMinute: "Minit",
                generalMinutes: "Minits",
                generalSearch: "Cari",
                generalSearchPlaceHolder: "Cari Sini",
                generalTag: "Tag",
                generalSeller: "Penjual",
                generalBuyer: "Penbayar",
                generalFieldWarning: "medan idperlukan",
                transTitle: "Transaksi",
                transReceiptNumber: "Nombor Resit",
                transDetailTitle: "Perincian Transaksi",
                transItem: "Penghuraian",
                transQty: "Qty",
                transPrice: "Harga",
                transTotalAmt: "Jumlah Amt",
                transAdditional: "Tambahan:",
                transPromotionDis: "Diskaun Promosi",
                transSpecialDis: "Diskaun istimewa",
                transPaidByCash: "Bayar dengan Wang Tunai",
                transPaidByCC: "Bayar dengan Kad Kredit",
                transChange: "Tukar",
                transSubtotal: "Jumlah Kecil",
                transDiscount: "Diskaun",
                transServiceCharge: "Caj Perkhidmatan",
                transRoundUp: "Nilai Kemasukan Adj.",
                transTotal: "Jumlah",
                transBillNo: "Bil No",
                transCashier: "Juruwang",
                transTable: "Meja",
                transDineIn: "Makan Sini",
                transTakeaway: "Bungkus",
                transPromotion: "Promosi",
                transPax: "Pax",
                transPaymentTime: "Tarikh",
                transVoid: "Batal Bil",
                transRevMsg: "Akan Dibatalkan",
                transWaiter: "Pelayan:",
                transItemReturn: "Barang Pulang",
                transWriteOff: "Batal",
                transRestock: "Restock",
                transClientName: "Tambah Nama Pelanggan",
                transClient: "Nama Pelanggan",
                transClientNameWarning: "Nama Pelanggan diperlukan",
                transExportReport: "Export Acct' Excel",
                transEditDate: "Edit Tarikh Urus Niaga",
                salesDailyTitle: "Laporan Jualan Harian",
                salesHourlyTitle: "Laporan Jualan Jam",
                salesItemTitle: "Laporan Item Bulanan",
                salesItemDailyTitle: "Laporan Item Hari",
                salesItemByTag: "Laporan Jualan Bulanan mengikut Tag",
                salesMonthlyTitle: "Laporan Jualan Bulan",
                salesYearlyTitle: "Laporan Jualan Tahun",
                salesNetSalesDaily: "Jualan Bersih Harian",
                salesTotalNetSales: "Jumlah Jualan Bersih",
                salesTotalNetSalesWoTax: "Jumlah Jualan Bersih t/t",
                salesComPrevDay: "Berbanding Hari Sebelum",
                salesFilter: "Penapis",
                salesSelectOption: "Sila pilih Pilihan",
                salesOption: "Pilihan",
                salesItem: "Barang",
                salesFrom: "Dari",
                salesCategory: "Kategori",
                salesGroup: "Tags",
                salesYearNetSales: "Jualan Bersih Tahunan - 5 Tahun Terakhir",
                salesComLastYear: "berbanding Tahun Lepas",
                salesOptionWarning: "Sila pilih Tajuk Pilihan",
                salesOutletTitle: "Laporan Jualan Outlet",
                salesOutlet: "Outlets",
                salesTagWarning: "Sila Pilih Tag",
                itemOutletDelete: "Batal Item Dari",
                itemAddTitle: "Nambah Produk",
                itemCatEmpty: "Nama tidak boleh dikosongkan",
                itemGrpEmpty: "Nama Kumpulan tidak boleh dikosongkan",
                itemCatAdd: "Tambah Kategori Baru",
                itemGrpAdd: "Tambah Kumpulan Baru",
                itemCatName: "Nama kategori",
                itemGrpName: "Nama Kumpulan",
                itemSelectEdit: "Pilih Item untuk Edit",
                itemCodeId: "Kod Id",
                itemBarcodeId: "Barcode",
                itemProductName: "Nama Produk",
                itemProductNameShort: "Nama Produk Pendek",
                itemDescription: "Penghuraian",
                itemPictureRatio: "Nisbah gambar 4: 3 (lebar: tinggi)",
                itemSelectCat: "Pilih Kategori",
                itemSelectGrp: "Tambah Tag",
                itemQty: "Kuantiti",
                itemUnit: "Unit",
                itemLowStock: "Peringatan Saham Rendah",
                itemNonStock: "Tidak Ade Stok",
                itemRawMaterial: "Adakah Bahan Mentah",
                itemMaxDiscount: "Max diskaun dalam %",
                itemUnitWarning: "Unit diperlukan",
                itemCost: "Kos",
                itemAveCost: "Ave. Kos",
                itemMinPrice: "Min harga",
                itemHasOption: "Mempunyai pilihan:",
                itemCodeWarning: "codeId Produk diperlukan",
                itemNameWarning: "Nama Produk diperlukan",
                itemNameShortWarning: "Nama Produk Pendek diperlukan",
                itemNameShortDes: "Gunakan untuk Memaparkan pada Resit",
                itemOptNotReq: "Bahan Baku, Penyediaan Pilihan tidak diperlukan",
                itemAddOpt: "Tambah Pilihan",
                itemOptName: "Nama Pilihan",
                itemOptWarning: "Nama Pilihan diperlukan",
                itemOptNameShort: "Nama Pilihan Pendek",
                itemMultiple: "adalah berbilang:",
                itemOptList: "Senarai Pilihan:",
                itemFrmPro: "Produk dari",
                itemManual: "Manual",
                itemOptItem: "Barang pilihan",
                itemOptItemWarning: "Barang Pilihan diperlukan",
                itemOptItemShortWarning: "Barang Pilihan Pendek diperlukan",
                itemOptItemShort: "Barang Pilihan Pendek",
                itemPrice: "Harga",
                itemRetailPrice: "Harga Runcit",
                itemChargeable: "Boleh Dikenakan:",
                itemNoOptWarning: "Pilihan Tidak Tambah",
                itemAddOptFrmPro: "Tambah Pilihan dari Produk",
                itemFilter: "Produk Penapis",
                itemEvent: "Nama Promosi",
                itemPriceList: "Senarai Harga",
                itemPriceListWarning: "Harga adalah di bawah Min. Harga",
                itemDiscount: "Diskaun (dalam %)",
                itemDiscountWarning: "Diskaun lebih besar daripada Max. Diskaun",
                itemTaxWarning: "Cukai tidak boleh dikosongkan",
                itemFinalPrice: "Harga Runcit Akhir",
                itemPriceType: "Jenis Harga:",
                itemFixCost: "Kos Tetap",
                itemVarCost: "Kos Berubah",
                itemPriceNoReq: "Bahan Baku, Persediaan Harga tidak diperlukan",
                itemEdit: "Edit Produk",
                itemSelectPro: "Pilih Produk",
                itemWOTax: "w/o Cukai",
                itemTax: "Cukai",
                itemProDetail: "Terperinci Produk",
                itemOnHand: "Sekarang Stok Kuantiti",
                itemRemove: "Batalkan Item",
                itemRevMsg: "akan dibatalkan secara kekal.",
                itemCatWarning: "Kategori diperlukan",
                itemProList: "Senarai Produk",
                itemCodeDuplicate: "Pendua Id Kod",
                itemImport: "Import Produk",
                itemExport: "Export Produk",
                itemExportBarcode: "Export Barcode",
                itemImportFromExcel: "Import Produk melalui Excel file",
                itemPhrase1: "Download a",
                itemPhrase2: "Templat Saham",
                itemPhrase3: "untuk melihat contoh format yang diperlukan.",
                itemEmptyFile: "Fail Kosong",
                itemPlusMinus: "Tambah/Tolak",
                itemAvailable: "Produk yang Terdapat Dalam",
                itemNewModifier: "Tambah Pilihan",
                itemModifier: "Pilihan Lain",
                itemModifierSub: "Pilihan lain digunakan untuk menambah beberapa pilihan,Seperti: Kurang Garam, Kurang Gula, Tambah Gula",
                itemVariant: "Pilihan",
                itemVariantSub: "Pilihannya adalah untuk membezakan klasifikasi item,Seperti: Besar, Medium, Kecil",
                itemAddMod: "Tambah/Tukar Pilihan Lain",
                itemSequence: "Urutan",
                itemCatExist: "Kategori sedia ada",
                itemCatRemove: "Batal Kategori",
                receiveConfirm: "Sah Terima",
                receiveConfirmWarning: "Sebaik sahaja anda menandakan sebagai 'Diterima', anda tidak dapat mengedit lagi (kecuali Batal)",
                receiveTitle: "Pembelian Invois",
                receiveNew: "Pembelian Invois Baru",
                receiveTotalAmt: "Jumlah Keseluruhan",
                receiveTotalAmtWarning: "Jumlah Keseluruhan Salah",
                receiveTotalAmtWarning2: "Jumlah Keseluruhan diperlukan",
                receiveHolder: "Invois",
                receiveBreakDown: "Rosak",
                receiveSearch: "Cari",
                receiveUnitCon: "Penukaran Unit",
                receiveCostWarning: "Kos diperlukan",
                receiveQtyWarning: "Kuantiti diperlukan",
                receiveUnitWarning: "Unit diperlukan",
                receiveUnitConWarning: "Penukaran diperlukan",
                receiveItem: "Menerima Items",
                receiveRemove: "Batal Stok Terima",
                receiveSelectPur: "Menerima yang Dipilih",
                receiveInvoice: "Pembelian Invois No",
                receiveInvoiceWarning: "Invois No diperlukan",
                receiveSupplier: "Pembekal",
                receiveSupplierWarning: "Pembekal diperlukan",
                receiveSupHolder: "Carian Dengan Pembekal Id atau Nama Pembekal",
                receiveDate: "Menerima tarikh",
                receiveUploadInv: "Naik Invois",
                receiveUploadInvWarning: "Invois Gambar diperlukan",
                receiveDetail: "Menerima Butiran",
                receivePOHelper: "Mendapatkan Item dari Pesanan Pembelian",
                receiveOrderCost: "Mengarahkan Kos",
                receiveOrderQty: "Mengarahkan Kuantiti",
                receiveOrderUnit: "Mengarahkan Unit",
                stockAdjNew: "Pelarasan Stok Baru",
                stockAdjTitle: "Pelarasan Stok",
                stockAdjHolder: "No. Rujukan",
                stockAdjName: "Diselaraskan oleh",
                stockAdjItem: "Barang Diselaraskan",
                stockInTitle: "Stok Masuk",
                stockOutTitle: "Stok Keluar",
                stockInNew: "Stok In Baru",
                stockTakeNew: "Stok Semak Baru",
                stockOutNew: "Stok Keluar Baru",
                stockInEdit: "Stok In Edit",
                stockRefNo: "No. Rujukan",
                stockRefNoWarning: "No. Rujukan diperlukan",
                stockInDate: "Stock Pada Tarikh",
                stockReason: "Sebab",
                stockReasonWarning: "Sebab tidak dapat dikosongkan",
                stockInList: "Senarai Stok",
                stockInRemove: "Batalkan Stok",
                stockAutoGenerate: "Menjana Auto",
                stockOutEdit: "Edit Stok Keluar",
                stockOutDate: "Tarikh Stok Keluar",
                stockOutList: "Senarai Stok Keluar",
                stockOutRemove: "Batal Stok Keluar",
                stockCountItem: "Item Dikira",
                stockUncountItem: "Barang Tidak Dikira",
                stockUncountAction: "Sila pilih tindakan untuk Item tidak dikira:",
                stockKeep: "Terus Menjangka Qty",
                stockReset: "Tetapkan Kuantiti Ke 0",
                stockExpCountItem: "Item Dikira Eksport",
                stockExpUncountItem: "Export Uncounted Items",
                stockExpItem: "Item Eksport yang Tidak Terkira",
                stockCounteQty: "Kira Kuantiti",
                stockTakeTitle: "Semak Stok",
                stockTakeCount: "Kiraan Stok",
                stockTransferSourceOutlet: "Sumber Outlet",
                stockTransferDestOutlet: "Destinasi Outlet",
                stockTransferShip: "Hantar",
                stockTransferSavenShip: "Simpan dan Hantar",
                stockTransferReceive: "Terima",
                stockTransferAveCost: "Ave. kos",
                stockTransferOriQty: "Semasa Di Tangan/Current On Hand",
                stockTransferConfirmShip: "Mengesahkan Hantar",
                stockTransferItemToTransfer: "Item Untuk Dipindahkan",
                stockTransferOutletWarning: "Sila Pilih Outlet",
                stockTransferDeliveryDate: "Tarikh Hantar",
                stockTransferShipWarning: "Sebaik sahaja anda ditandakan sebagai dihantar, anda tidak boleh mengedit lagi.",
                stockTransferGeneral: "Maklumat Umum",
                stockTransferCreateDate: "Membina Tarikh",
                stockTransferNew: "Stok baru pindah",
                stockTransferItemDuplicate: "Item Duplikat. Sila gabungkan kepada 1",
                stockLowItems: "Stok Item Rendah",
                stockPurchaseNew: "Perintah Belian Baru",
                stockPurchaseConfirm: "Perintah Pembelian Lengkap",
                stockPurchaseConfirmWarning: "Sebaik sahaja anda ditandakan sebagai selesai, anda tidak boleh mengedit lagi kecuali mengeluarkan PO",
                stockPurchaseItemToPurchase: "Item yang Dibeli",
                stockPurchaseOrderDate: "Tarikh Pesanan",
                stockPurchaseRemove: "Batalkan Pesanan Pembelian",
                stockPurchaseShouldInclude: "Termasuk Item Stok Rendah",
                stockPurchaseDeliverTo: "Hantar Kepada",
                supplierNew: "Pembekal Baru",
                supplierEdit: "Pembekal Edit",
                supplierId: "Pembekal Id",
                supplierIdWarning: "Pembekal Id diperlukan",
                supplierName: "Pembekal Nama",
                supplierNameWarning: "Pembekal Nama diperlukan",
                supplierRemove: "Batalkan Pembekal",
                eventValid: "Tempoh Promosi:",
                eventValidWarning: "Tempoh promosi diperlukan",
                eventAllDay: "Sepanjang Hari",
                eventSpecificDay: "Pada masa tertentu",
                eventStartTime: "Masa Mula",
                eventStartTimeWarning: "Masa Mula diperlukan",
                eventEndTime: "Masa Akhir",
                eventEndTimeWarning: "Masa Akhir diperlukan",
                eventPercentage: "Peratusan",
                eventApplyTo: "Promosi Diguna Untuk:",
                eventPriority: "Keutamaan:",
                eventPrioDes: "Sekiranya pelbagai promosi bertindih pada masa yang sama, Keutamaan dengan nombor yang lebih tinggi akan menggantikan Promosi lain",
                eventDaily: "Promosi Setiap Hari:",
                eventStartDate: "Tarikh Permulaan Promosi",
                eventEndDate: "Tarikh Akhir Promosi",
                eventEdit: "Promosi Edit",
                eventSelectExisting: "Pilih Sedia ada Promosi",
                eventNameWarning: "Nama Promosi diperlukan",
                eventType: "Jenis Promosi:",
                eventRecurring: "Promosi Berulang (E.g. Happy Hour)",
                eventOneDay: "Promosi Satu Hari (E.g. Anniversary)",
                eventRange: "Jarak Promosi Pada Tarikh Spesifik",
                eventTypeWarning: "Jenis Promosi diperlukan",
                eventDate: "Tarikh Promosi",
                eventRemove: "Batal Promosi",
                eventPromotionOn: "Mula Promosi",
                eventCondition: "Syarat Promosi",
                eventConditionWarning: "Sila Pilih Syarat",
                eventFilterType: "Produk yang Penapis",
                eventInclude: "Produk termasuk:",
                eventExclude: "Produk tidak termasuk:",
                eventShouldExclude: "Produt yang tidak termasuk",
                eventStartFrom: "Promosi Bermula Dari",
                eventMinAmount: "Belanja ke atas:",
                eventAmount: "Jumlah",
                eventNew: "Tambah Promosi",
                eventList: "Senarai Promosi",
                eventMaxQty: "Max Qty",
                eventMinQty: "Min Qty",
                eventDiscountType: "Jenis Diskaun",
                eventDiscountTypeWarning: "Jenis Diskaun diperlukan",
                eventFixedPrice: "Harga Runcit(Sebelum Cukai)",
                eventBundle: "Item Bundle",
                eventEffect: "Diskaun digunakan untuk:",
                eventFollowingItem: "Item Berikut",
                eventSimple: "Promosi",
                eventAdvance: "Promosi mendahulukan",
                eventQtyWarning: "Kuantiti diperlukan",
                eventItems: "Barang",
                tableNew: "Meja Pengurusan",
                tableFloor: "Tingkat",
                tableOption: "Meja Pilihan",
                tableAddFloor: "Tambah Tingkat",
                tableEditFloor: "Edit Tingkat",
                tableFloorName: "Nama Tingkat",
                tableFloorNameWarning: "Nama Tingkat diperlukan",
                tableEdit: "Tambah/Edit Meja",
                tableLabel: "Meja Label",
                tableSelectFloor: "Pilih Tingkat",
                tableSelectFloorWarning: "Sila Pilih Tingkat",
                tableLabelWarning: "Meja Label diperlukan",
                tableLabelDuplicate: "Meja Label Pendua",
                tableSelected: "Pilih Meja",
                tableRemove: "Batal Meja",
                tablePrefix: "Prefix",
                tableStartNo: "Bermula No",
                tableStartNoWarning: "Bermula No diperlukan",
                tableEndNo: "Akhir No",
                tableEndNoWarning: "Akhir No diperlukan",
                tableName: "Meja",
                tableOutletFloorWarning: "Sila pilih outlet dan tingkatan",
                employeeSelect: "Pilih User",
                employeePosPermission: "POS Kebenaran",
                employeePosHolder: "Digunakan oleh POS",
                employeeWebPermission: "BackEnd Kebenaran",
                employeeWebHolder: "Digunakan oleh Website",
                employeePosPwdWarning: "Sila taip Kata Laluan jika membenarkan pengguna mengakses POS",
                employeePwdWarning: "Sila taip Kata Laluan jika membenarkan pengguna mengakses Backend",
                employeePwdWarning2: "Kata laluan mesti> = 8 aksara dan sekurang-kurangnya Satu Huruf Besar",
                employeePosPwdWarning2: "Sila Taip Kata Laluan Sekali",
                employeeEdit: "Edit User",
                employeePwd: "Kata Laluan",
                employeePosPwd: "POS Kata Laluan",
                employeePosAccess: "Guna untuk POS akses",
                employeeTitle: "Pekerja",
                employeeNew: "Pekerja Baru",
                employeeAccessTitle: "Pekerja kawalan akses",
                systemResName: "Nama Restoran",
                systemResNameWarning: "Nama Restoran diperlukan",
                systemRegNo: "Reg. No",
                systemRegNoWarning: "Reg. No diperlukan",
                systemStreet: "Nama Jalan/Bangunan",
                systemStreetWarning: "Nama Jalan/Bangunan diperlukan",
                systemReqSup: "Memerlukan Kelulusan Penyelia",
                systemDiscounts: "Diskaun",
                systemDisOverall: "Digunakan untuk diskaun Produk dan diskaun Keseluruhan",
                systemDisDes: "Memerlukan Kelulusan Diskaun adalah lebih besar daripada Diskaun Max Produk",
                systemDisExclude: "Tidak termasuk Pilihan Daripada Diskaun Istimewa",
                systemServiceCharge: "Caj Perkhidmatan (dalam %)",
                systemServiceTaxable: "Adekan Caj Cukai Perkhidmatan?",
                systemServiceTax: "Caj Cukai Perkhidmatan (dalam %)",
                systemPayment: "Cara Pembayaran",
                systemCash: "Wang Tunai",
                systemCredit: "Kad Kredit",
                systemPrinter: "Tambah/Edit Mesin Pencetak",
                systemPrinters: "Mesin Pencetak",
                systemPrinterName: "Tajuk Mesin Pencetak",
                systemPrinterNameWarning: "Tajuk Mesin Pencetak diperlukan",
                systemPrinterIP: "Mesin Pencetak Ip Address",
                systemPrinterIPWarning: "Mesin Pencetak IP Address diperlukan",
                systemPrinterType: "Jenis Mesin Pencetak:",
                systemPrinterCashier: "Juruwang",
                systemPrinterBev: "Dapur/Minuman",
                systemPrinterPro: "Produk yang akan dicetak",
                systemPrinterProCat: "Produk Kategori",
                systemPrinterNew: "Tambah Mesin Pencetak Baru",
                systemPrinterTest: "Cetak Ujian",
                systemPrinterGuestCheck: "Cek Pelangan",
                systemPrinterBig: "Besar",
                systemPrinterSmall: "Kecil",
                systemPrinterWhole: "Cetak Semua Barang dalam satu ketas",
                systemPrinterSingle: "Cetak Barang Berasingan",
                systemPrinterBoth: "Cetak Semua Dan Cetak Berasingan",
                systemPrinterReceipt: "Cetak Barang Pilihan",
                systemPrinterSize: "Huruf Besah/Kecil",
                systemPrinterType2: "Cetak Format",
                systemPrinterDevices: "Peranti",
                systemReasonAdd: "Tambah Sebab",
                systemReason: "Sebab",
                systemReasonCancel: "Batalkan Sebab Pesanan",
                systemReasonVoid: "Tak sah Sebab Alasan",
                systemReasonRefund: "Bayaran Balik Alasan",
                systemRoundUpTo: "Rounding Adj. to",
                systemCurrencySymbol: "Matawang Simbol",
                systemCurrency: "Matawang",
                systemNewBank: "Wang Kertas Baru / Duit Syiling",
                systemBankList: "Senarai Wang Kertas adn Duit Syiling",
                systemTaxReg: "Cukai Reg. No",
                systemTaxName: "Nama Cukai",
                systemTaxNameWarning: "Nama Cukai diperlukan",
                systemTaxApplyToAll: "Pakai Cukai ke Semua Produk",
                systemTax: "Cukai (dalam %)",
                systemGeneral: "Persediaan Umum",
                systemSetup: "Sistem Persediaan",
                systemTaxSetup: "Cukai Persediaan",
                systemPaymentSetup: "Persediaan Perkhidmatan & Pembayaran",
                systemDiscountSetup: "Persediaan Diskaun",
                systemOther: "Lain",
                systemDeviceConnection: "Sambungan Peranti",
                systemBackup: "Backup Database",
                systemBackupSecondary: "Backup semua data ke dalam storan peribadi",
                systemDelete: "Tetapkan Semula Data Transaksi",
                systemDeleteSecondary: "Amaran: Semua urus niaga, stokIn, stokOut, stokTake, dan data anjakan akan dikeluarkan secara kekal",
                systemDeleteAll: "Tetapkan Semula Semua Data",
                systemDeleteAllSecondary: "Amaran: Semua data akan dikeluarkan secara kekal",
                systemTypeText: "Sila taip",
                systemTextMatchWarning: "Teks Tidak Pelawanan",
                systemPrinterGuestCheck: "Cek Pelangan",
                systemOtherPaymentMethod: "Cara Pembayaran Lain",
                systemPaymentMethod: "Cara Pembayaran",
                systemPaymentType: "Jenis Pembayaran",
                systemOtherPaymentMethodWarning: "Sila pilih care pembayaran",
                shOpeningTime: "Jam Buka ",
                shClosingTime: "Jam Tutup",
                shOpeningAmount: "Jumlah Laci Permulaan",
                shExpectedAmount: "Jumlah Laci Yang Diharapkan",
                shClosingAmount: "Jumlah Laci Sebenar",
                shShiftEdit: "Peralihan Edit",
                shOpeningDate: "Tarikh Buka",
                shClosingDate: "Tarikh Tutup",
                shOpening: "Peralihan Buka",
                shClosing: "Peralihan Tutup",
                shTransaction: "Transaksi",
                shShort: "Pendek",
                shTotalPaymentReceive: "Jumlah Pembayaran Diterima",
                shCash: "Wang Tunai",
                shCredit: "Kredit",
                shVisa: "Visa",
                shMaster: "Master",
                shAmex: "Amex",
                shTotalGrossSale: "Jumlah Jualan Kasar",
                shRoundAdj: "Rounding Adj",
                shSalesSummary: "Jumlah Jualan",
                shSalesSummaryByTag: "Jumlah Jualan Mengikut Tag",
                shNoVoid: "No bil yang tak sah",
                shNoRefund: "No bayaran balik",
                shVoidAmount: "Jumlah Bil Tak Sah",
                shRefundAmount: "Jumlah Bayaran Balik",
                shAt: "pada",
                shBy: "oleh",
                shDineIn: "Makan Sini",
                attendAllowDevice: "Peralatan yang Dibenarkan Masa Perkerja",
                shServiceCharge: "Caj Perkhidmatan",
                attendAllowDevice: "Peranti Benarkan Masa In/Out",
                attendRemove: "Batalkan Kehadiran Pekerja",
                attendEdit: "Edit Kehadiran Pekerja",
                attendTimeIn: "Masa Masuk",
                attendTimeOut: "Masa Keluar",
                attendTotalDuration: "Jumlah Kerja Jam",
                outletName: "Nama Outlet",
                outletEdit: "Tambah/Edit Outlet",
                outletSetup: "Persediaan Outlet",
                outletShouldClone: "Klon dari lain outlet",
                outletClone: "Outlet",
                outletCloneWarning: "Sila pilih saluran untuk diklon",
                outletNew: "Tambah Outlet Baru",
                outletAll: "Semua Outlets",
                outletWarning: "Sila pilih outlet",
                displayAdd: "Menambah Paparan Pelanggan",
                displayNew: "Tambah/Tukar Paparan Pelanggan",
                displayTitle: "Tajuk",
                displayTitleWarning: "Sila Isikan",
                displayPicture: "Gambar",

                systemPrinterSticker: "Pelekat",
                systemPrinterHeight: "Pelekat Ketinggian",
                systemPrinterWidth: "Pelekat Keluasan",
                systemPrinterGap: "Pelekat Celah",

                generalPreview: "Cetak Pratonton",
                generalSubtotal: "Jumlah Kecil",
                generalDiscount: "Discount",
                generalTotalAmount: "Jumlah",
                generalRequireWarning: "Medan Borang Mesti Tulis",
                generalPrint: "Cetak",
                generalSwitchToDO: "Tukar ke DO",
                generalSwitchToST: "Tukar ke ST",
                menuDeliveryOrder: "Pesanan Penghantaran",
                menuPurchaseOrder: "Pesanan Pembelian",

                selfOrderBgImg: "Imej Latar Belakang",
                selfOrderRecommendation: "Makanan Cadangan",
                selfOrderRefreshWarning: "Semua Qr Code akan dibatalkan and menjana semula，Adakah anda pasti?",
                selfOrderQrCode: "Qr Code",
                selfOrderStatic: "Static",
                selfOrderDynamic: "Dynamic",
                generalGenerate: "Menjana",
                generalRefresh: "Menyegarkan",

                orderDetailTitle: "Perintah",
                orderClientName: "Nama Pelanggan",
                orderTime: "Masa",

                transLast4Digit: "4 Digit Terakhir",
                generalAddFromModifier: "Tampah Dari Modifier",
                generalStaffBenefit: "Kakitangan Benefit",
                menuStaffMealSetup: "Kakitangan Makanan Setup",
                menuStaffMeal: "Kakitangan Makanan",
                menuStaffMealList: "Kakitangan Makanan List",
                menuStaffMealAdd: "Tambah Kakitangan Makanan",

                staffMealMaxAmount: "Jika Ada Max. Diskoun (Bulan)",
                staffMealApplyToAll: "Semua Barang Ada Diskoun",
                eventExcludeOption: "Promosi ini termasuk barang-barang tambahan",
                systemPrinterFooter: "Footer",
                systemPrinterConType: "Mengyambung Jenis",
                salesCustomer: "Pelanggan",
                salesAveCustomerPerDay: "Pelanggan/ day",
                salesAveSpendingPerPerson: "$/Pelanggan",
                salesTableTurnOver: "Kadar Perolehan Meja",
                salesTableDistribute: "Taburan Meja",
                itemRecipe: "Recipe",
                itemAddRecipe: "Tambah Recipe",
                systemPrinterNoReceipt: "Cetak Kuantiti",
                shPayIn: "Cash In",
                shPayOut: "Cash Out",
                menuPayInOut: "Cash In / Out",
                menuAuditTrail: "Audit Trail",
                dashNetSaleWeekly: "Jualan Bersih-Setiap Minggu",
                systemServiceExclude: "Tidak Termasuk Caj Perkhidmatan Dari BungKus",
                menuTableSetting: "Meja Persediaan",
                menuSaleReportSetting: "Report Persediaan",
                systemTableSettingAdd: "Tambah Meja tetapan",
                generalDuration: "Duration",
                generalColor: "Warna",
                systemServiceChargeMode: "Caj Perkhidmatan dikenakan kepada semua barang",
                itemAllowDis: "Membenarkan Diskaun",
                itemAllowFOC: "Membenarkan FOC",
                itemIsSerial: "Ada Serial Nombor",
                itemAllowEnt: "Membenarkan Entertainment",
                itemUnitAdd: "Unit",
                itemUnitValue: "Nilai",
                itemEntAmt: "Hiburan",
                itemCommAmt: "Suruhanjaya",
                menuPriceBook: "Barang Barang Harga",
                itemUnitRemove: "Batalkan Unit",
                pricebookRemove: "Batalkan Barang Barang Harga",
                pricebookAdd: "Tambah Barang Barang Harga",
                pricebookAddEdit: "Tambah/Edit Price Book",
                pricebookPromotion: "Untuk Promosi",
                pricebookApplyForAll: "Kepada Semual",
                pricebookCustomer: "Pelanggan",
                shiftCode: "Shift No",
                menuStockBalance: "Baki Stok",
                eventPricebook: "Promosi berdasarkan barang barang Harga",
                menuCustomer: "Pelanggan Pengurusan",
                menuCustomerList: "Pelanggan",
                menuCustomerGroup: "Kumpulan Pelanggan",
                menuCustomerGroupAdd: "Tambah Kumpulan Pelanggan",
                customerGroupRemove: "Batalkan Kumpulan Pelanggan",
                customerAdd: "Tambah Pelanggan",
                customerRemove: "Batalkan Pelanggan",
                customerEdit: "Edit Pelanggan",
                customerName: "Nama Pelanggan",
                menuReserveSetting: "Tetapan Rizab",
                stockSerialNo: "Serial Nombor",
                menuReservation: "Tempahan",
                reserveAdd: "Tambah Tempahan",
                reserveEdit: "Edit Tempahan",
                reserveShift: "Shift",
                reserveEmployee: "Kakitangan",
                reserveDate: "Tarikh Tempahan",
                menuSerialNumber: "Serial Nombor",
                generalPrintBarcode: "Cetak Barcode",
                generalCopyAndPaste: "Salin dan Tampal",
                customerLastPurchase: "Tarikh Pembelian Terakhir",
                customerTotalSpent: "Jumlah Belanja",
                customerTotalTrans: "Jumlah Transaction",
                employeeLevyOrCPF: "Levy Or CPF",
                employeeLevyAmount: "Levy/CPF Amount",
                entMaxBringFwd: "Max. Membawa ke bulan depan (bulan)",
                entMinCommission: "Min. Commision untuk Levy Pengecualian",
                entRedeemPts: "Free ENT Pts",
                systemENTSetup: "ENT Persediaan",
                itemMMReward: "Min. Qty untuk menikmati Free ENT",
                itemMMRedeem: "Reward Pts To Redeem",
                entTotalCommission: "Jumlah Commission",
                entTotalENT: "Jumlah ENT",
                entClaim: "ENT Claimed",
                entMCredit: "MCredit",
                reserveCancel: "Batalkan Tempahan",
                menuSalesReportDailyOverall: "Laporan Jualan Harian",
                salesTotal: "SALES TOTAL",
                menuMummyReturn: "M Credit Refund",
                mummyRemove: "Batalkan M Credit Refund",
                entMCreditReturn: "M Credit Refund",
                systemBottleInSetup: "Bottle In Setup",
                systemShiftSetup: "Shift Setup",
                systemShiftSequence: "Sila mengikut urutan",
                systemPrinterModel: "Printer Model",
                itemEntQty: "Kuantiti - Actual",
                itemLevyQty: "Kuantiti - LEVI",
                employeeLevySubsidizeAmount: "Jumlah LEVI Subsidize",
                employeeMinBottle: "Min. Bottle",
                menuSalesReportItemCheck: "Item Sales Summary",
                employeeMultiply: "Multiply",
                menuEmployeePerformanceDaily: "Employee Daily Performance",
                menuSingerPerformance: "Singer Performance",
                itemCategoryAdd: "Add Category",
                itemIsSub: "Is Sub Category",
                itemMainCategory: "Main Category",
                menuSalesSystem: "System Sales Summary",
                salesMetrics: "Sales Metrics",
                netSales: "Net Sales",
                revenue: "Revenue",
                taxCollected: "Tax Collected",
                totalRev: "Total Revenue",
                nonRevenueSVC: "NON Revenue SVC",
                totalCollection: "Total Collections",
                itemDiscounts: "Item Discounts",
                subDiscount: "Subtotal Discounts",
                totalDiscount: "Total Discounts",
                costOfGoodSold: "Cost Of Goods Sold",
                otherIncome: "Other Income",
                adjustment: "Adjustment",
                generalReturn: "Returns",
                generalVoid: "Voids",
                generalCreditTotal: "Credit Total",
                generalRoundingTotal: "Rounding Total",
                mgrVoid: "Mgr Voids",
                errorCorrect: "Error Corrects",
                cancels: "Cancels",
                giftCard: "Gift Cards",
                giftCardSales: "Gift Card Sales",
                tips: "Tips",
                chargeTips: "Charge Tips",
                cashTips: "Cash Tips Decl",
                indirectTips: "Indirect Tips",
                totalTips: "Total Tips",
                tipsPaid: "Tips Paid",
                tipsDue: "TipsDue",
                generalChecks: "Checks",
                carriedOver: "Carried Over",
                checkBegun: "Checks Begun",
                checkPaid: "Checks Paid",
                checkTransOuts: "Transferred Out",
                checkTransIn: "Transferred In",
                generalOutstanding: "Outstanding",
                generalDeposits: "Deposits",
                cashDue: "Cash Due",
                deposit: "Deposit",
                overshort: "Over / Short",
                cashCollected: "Cash Collected",
                paidIn: "Paid In",
                paidOut: "Paid Out",
                tipsPaid: "Tips Paid",
                businessDate: "Business Dates",
                generalLocation: "Locations",
                revenueCenters: "Revenue Centers",
                systemResNameShort: "Company Name (Short)",
                filterList: "Filter List",
                itemExtraInfo: "Extra Info",
                groupRewardPoints: "Additional Group Rewards",
                hasBirthdayScheme: "Birthday Rewards",
                birthdayMonth: "Birthday Month",
                birthday: "On That Day",
                hasExpiry: "Reward Points has Expiry date",
                pointValidDuration: "Reward Points valid for (days)",
                customerPointForfeit: "Forfeit",
                systemLoyaltyPoints: "Loyalty Points",
                totalReward: "Total Earned Points",
                totalRedeem: "Total Redeemed Points",
                totalForfeit: "Total Forfeits Point",
                totalAvailable: "Total Available Points",
                printCustomerDetail: "Print Customer Detail",
                printBreakdownRewardPts: "Print Rewards Detail",
                customerPromo: "Membership Promotions",
                itemHasExpiry: "Has expiry date",
                expiryDate: "Expiry Date",
                noOfDay: "No Of Days",
                itemBreakdown: "Recent Bought Item",
                cardNo: "Card No",
                customerTotalTrans: "Total Transaction",
                customerTotalSpent: "Total Spending",
                customerLastPurchase: "Last Purchase",
                customerPointEarned: "Point Earned",
                customerPointRedeemed: "Point Redeem",
                customerPointAvailable: "Point Available",
                menuCustomerSetting: "Customer Setting",
                redeemConversion: "Conversion (1pts = ? $)",
                rewardConversion: "Conversion (1$ = ? pts)",
                rewardType: "Reward Pts gain base on:",
                rewardBaseOnTotal: "Bill Amounts",
                rewardBaseOnIndividual: "Individual Items",
                mutiplyRewardPoints: "Additional Reward Points",
                menuCustomerReward: "Customer Loyalty Rewards",
                customerRewardAdd: "New Reward",
                customerRewardEdit: "Edit Reward",
                RechargeableCard: "For Member Top Up",
                newMemberReward: "New Member Reward",
                maxRedemption: "Max. Redemption",
                qlubPrefix: "Qlub Prefix",
                qlubSeq: "Qlub Sequence",
            }
        ];
    }

    onLanIndex(data) {
        this.lanIndex = data;
    }

}
export default alt.createStore(languageStore, 'languageStore');